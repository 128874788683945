import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
} from "reactstrap";
import Browser from "assets/images/icons/browser-device.svg";
import Mobile from "assets/images/icons/mobile-icon.svg";

function LaunchModal(props) {
  const [chooseLaunch, setChooseLaunch] = useState("Mobile");
  const handleLaunch = () => {
    if (chooseLaunch === "Web") {
      props.handleLaunchWeb();
      props.toggle();
    } else if (chooseLaunch === "Mobile") {
      props.handleLaunchApp();
      props.toggle();
    }
  };
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
      className="custom-modal"
    >
      <ModalHeader className="justify-content-center">
        Select the Device
      </ModalHeader>
      <ModalBody>
        <div className="form-group-wrap">
          <div className="form-group">
            <ButtonGroup className="device-group" aria-label="Basic example">
              <Button
                className={`modal-device ${
                  chooseLaunch === "Web" && "currentDevice"
                }`}
                name="Web"
                disabled={props.roomDetailsData?.assetWebURL ? false : true}
                onClick={(event) => setChooseLaunch(event.target.name)}
              >
                <img src={Browser} alt="Browser" className="mb-10" />
                Web
              </Button>
              <Button
                className={`modal-device ${
                  chooseLaunch === "Mobile" && "currentDevice"
                }`}
                name="Mobile"
                disabled={
                  props.roomDetailsData?.platform?.includes(1) ||
                  props.roomDetailsData?.platform?.includes(2)
                    ? false
                    : true
                }
                onClick={(event) => setChooseLaunch(event.target.name)}
              >
                <img src={Mobile} alt="Mobile" className="mb-10" />
                Mobile
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold gray-background-button"
          title="Close"
          onClick={() => {props.toggle(); setChooseLaunch("Mobile")}}
        >
          Close
        </button>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold green-background-button"
          title="Launch"
          onClick={handleLaunch}
        >
          Launch
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default LaunchModal;
