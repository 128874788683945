import ResetPassword from "./ResetPassword";

const ResetPasswordConfig = {
  component: ResetPassword,
  path: "/reset-password/:id",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      prelogin: true
    },
  },
  auth: false,
};

export default ResetPasswordConfig;


