import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import publishedWhite from "assets/images/icons/published-white.svg";
import editWhite from "assets/images/icons/edit-white.svg";
import { showMessage } from "store/common.action";
import CropModal from "./components/CropModal";
import { getProfileDetails } from "./store/userProfile.action";
import { getDateInFormat } from "utils";

function UserDetail() {
  const { profileDetails } = useSelector(({ profile }) => profile);
  const [prevName, setPrevName] = useState(profileDetails.name);

  const [name, setName] = useState(profileDetails.name);
  const [show, setShow] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    showInput && inputEl.current.focus();
  }, [showInput, show]);
  const inputEl = useRef(null);
  const dispatch = useDispatch();

  const CheckValidate = (value) => {
    const requiredMessage = "This field is required";
    const err = value
      ? value.length >= 2 && value.length < 30
        ? ""
        : "Name should be between 2 to 30 characters"
      : requiredMessage;
    return err;
  };
  const isFormValid = () => {
    if (name) {
      if (!error) {
        return true;
      } else {
        return false;
      }
    } else {
      const requiredMessage = "This field is required";
      const errormsg = name ? error : requiredMessage;

      setError(errormsg);
      return false;
    }
  };

  const handleChange = (event) => {
    setName(event.target.value);
    const err = CheckValidate(event.target.value);
    setError(err);
  };

  const handleEdit = () => {
    setShow(false);
    setShowInput(true);
    setError("");
    setShowBtn(true);
  };

  const handleUpdate = () => {
    if (isFormValid()) {
      if (prevName === name.trim()) {
        setShowInput(false);
        setShowBtn(false);
      } else {
        const successCB = () => {
          setShowInput(false);
          setPrevName(name.trim());
          dispatch(showMessage("Name changed successfully", "info"));
          setError("");
          setShowBtn(false);
          // dispatch(getProfileDetails("GET",{},()=>{}));
        };

        dispatch(getProfileDetails("PUT", { name: name.trim() }, successCB));

        setShow(true);
      }
    } else {
      console.log("error", error);
    }
  };

  return (
    <div className="user-profile-detail">
      <div className="upper-block d-flex">
        <div className="left-block d-flex align-items-start w-100">
          <div className="profile-img-wrap">
            <div className="profile-img">
              {profileDetails.profileImage ? (
                <img src={profileDetails.profileImage} alt="user-profile" />
              ) : (
                <p className="font-28 fw-semibold text-uppercase mb-0">
                  {profileDetails?.name?.[0]}
                </p>
              )}
            </div>
            <div className="upload-image">
              <span
                className="font-10 fw-semibold d-block text-center"
                onClick={toggleModal}
              >
                Change
              </span>
            </div>
            <CropModal
              isOpen={modal}
              toggle={toggleModal}
              profileImage={profileDetails?.profileImage}
            />
          </div>
          <div className="profile-summary-wrap">
            <div className="user-name d-flex align-items-start mb-3">
              <div>
                {showInput ? (
                  <>
                    <input
                      type="text"
                      ref={inputEl}
                      name="name"
                      onChange={handleChange}
                      value={name?.trimStart()}
                      {...(show && { disabled: true })}
                      className="form-control primary-input font-13 fw-medium edit-usrname"
                      maxLength="30"
                    // onBlur={() => {
                    //   setShowBtn(false);
                    //   setShowInput(false);
                    // }}
                    />
                    {error && (
                      <p className="d-block font-13 fw-normal invalid-feedback mb-2">
                        {error}
                      </p>
                    )}
                  </>
                ) : (
                  profileDetails?.name
                )}
              </div>
              &nbsp;&nbsp;
              {showBtn ? (
                <div className="update-usr-name">
                  <button
                    className="green-background-button font-14 fw-semibold"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <i className="edit-icon" onClick={handleEdit}>
                  <img src={editWhite} alt="edit-white" />
                </i>
              )}
            </div>
            <div className="font-14 fw-medium user-email">
              {profileDetails?.emailId}
            </div>
          </div>
        </div>
      </div>
      <div className="lower-block d-flex">
        <div className="detail-inner d-inline-flex align-items-center mb-2">
          <i className="d-inline-block me-3 published-icon opacity-80">
            <img src={publishedWhite} alt="published-white" />
          </i>
          <p className="font-16 fw-medium mb-0 me-2 opacity-80">
            User Created
          </p>
          <p className="font-16 fw-medium mb-0">
            {profileDetails.createdAt &&
              (getDateInFormat(profileDetails.createdAt) || "-")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDetail;
