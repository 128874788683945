import MyRoom from "./MyRoom";

const myRoomConfig = {
  component: MyRoom,
  path: "/myroom",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      prelogin: false
    },
  },
  auth: true,
};

export default myRoomConfig;
