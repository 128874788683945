import designService from "../../services/design.service";
import timerWhite from "assets/images/icons/timer-white.svg";
import memberWhite from "assets/images/icons/member-white.svg";
import dateTimeWhite from "assets/images/icons/date-time-white.svg";
import publishedWhite from "assets/images/icons/published-white.svg";
// import chatWhite from "assets/images/icons/chat-white.svg";
import RoomDetailsList from "components/RoomDetailsList/RoomDetailsList";
import { getDateInFormat } from "utils";

function ExperienceInfoTab({roomData}) {

  designService.setTabContentHeight();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });
  
  const roomDetailsList = [
    {
      id: 0,
      iconClass: "timer-icon",
      img: timerWhite,
      value: roomData?.duration + " minutes" || "-",
    },
    {
      id: 1,
      iconClass: "member-icon",
      img: memberWhite,
      value: "15 members",
    },
    {
      id: 2,
      iconClass: "date-time-icon",
      img: dateTimeWhite,
      title: "Created",
      value: roomData?.createdAt && (getDateInFormat(roomData?.createdAt) || "-"),
    },
    // {
    //   id: 3,
    //   iconClass: "date-time-icon",
    //   img: dateTimeWhite,
    //   title: "Room Time",
    //   value: roomData?.total_minutes || "-",
    // },
    {
      id: 4,
      iconClass: "published-icon",
      img: publishedWhite,
      title: "Published",
      value: roomData?.published && (getDateInFormat(roomData?.published) || "-"),
    },
    // {
    //   id: 5,
    //   iconClass: "chat-icon",
    //   img: chatWhite,
    //   title: "Sessions Created",
    //   value: roomData?.asset_room_count || "-",
    // },
  ];

  return (
    <div className="tab-pane-inner">
      <div className="content-outer">
        <div className="about-room pb-40">
          <p className="font-14 fw-thin custom-description">
            {roomData.description}
          </p>
          <RoomDetailsList list={roomDetailsList} />
        </div>
      </div>
    </div>
  );
}

export default ExperienceInfoTab;