import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import designService from "../../services/design.service";
import UserAcitvityTab from "./UserActivityTab";
import RoomDetailsTab from "./RoomDetailsTab";
import InvitedUserTab from "./InvitedUserTab";
import backArrowWhite from "assets/images/icons/back-arrow-white.svg";
import ExperienceInfoTab from "./ExperienceInfo";
import ContentSkeleton from "components/Skeletons/ContentSkeleton";
import { fetchRoomDetails } from "store/common.action";
import NoContent from "components/NoContent";
import AboutCompany from "components/AboutCompany";

function MyRoom({ location }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("room-details");
  const { listLoading, currentCard, midbarData, commonData } = useSelector(
    ({ midbar }) => midbar
  );
  const { roomDetailsLoading, roomDetailsData } = useSelector(
    ({ common }) => common
  );

  const history = useHistory();

  useEffect(() => {
    if (midbarData.length > 0 && location?.state?.loading) {
      history.replace({ ...history.location, state: { loading: false } });
    } else if (location?.state?.fromSocialPage) {
      history.replace({
        ...history.location,
        state: { fromSocialPage: false },
      });
    }
  }, [
    history,
    location?.state?.fromSocialPage,
    location?.state?.loading,
    midbarData.length,
  ]);

  designService.setTabContentHeight();
  designService.scrollToActiveTab();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  useEffect(() => {
    currentCard && dispatch(fetchRoomDetails(currentCard, () => {}));
  }, [currentCard, dispatch]);

  const { userActivityList, invitedUsers } = useSelector(({ room }) => room);

  const redirectData = JSON.parse(localStorage.getItem("redirectData"));

  useEffect(() => {
    toggle("room-details");
    // eslint-disable-next-line
  }, [currentCard]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return midbarData.length === 0 &&
    !roomDetailsLoading &&
    !listLoading &&
    !(location?.state?.loading === true) ? (
    <NoContent />
  ) : midbarData.length === 0 ||
    roomDetailsLoading ||
    (redirectData.flag && listLoading && !currentCard) ? (
    <ContentSkeleton />
  ) : (
    <div
      className={`content-wrapper-block event-details-block flex-grow-1${
        activeTab === "user-activity" &&
        userActivityList?.length === 0 &&
        "no-user-activity"
      }${
        activeTab === "invited-user" &&
        invitedUsers?.length === 0 &&
        "no-user-invited"
      }`}
      style={{ minWidth: "0" }}
    >
      <div className="event-title-block d-flex justify-content-between">
        <div className="content-title-wrapper d-flex flex-wrap align-items-center">
          <i
            className="back-arrow-icon"
            onClick={() => designService.toggleDetails()}
          >
            <img src={backArrowWhite} alt="back-arrow-white" />
          </i>
          <h3 className="font-22 fw-semibold event-title">
            {roomDetailsData.roomName}
          </h3>
          <h4
            className="font-16 fw-normal  text-blue"
            onClick={() => {
              window.open(
                `https://uat.realimmerse.app/publisher/${roomDetailsData.publisherId}`,
                "_blank"
              );
            }}
          >
            {roomDetailsData.publisherName}
          </h4>
        </div>
      </div>
      <Nav tabs className="primary-tab-nav d-flex">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "room-details",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("room-details");
            }}
          >
            Room
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "experience-info",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("experience-info");
            }}
          >
            Info.
          </NavLink>
        </NavItem>
        {commonData !== "Public" &&
          roomDetailsData.roomVisibility === 1 &&
          roomDetailsData.assetType === 1 && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "user-activity",
                  "font-16 fw-normal": true,
                  "cursor-pointer": true,
                })}
                onClick={() => {
                  toggle("user-activity");
                }}
              >
                Activities
              </NavLink>
            </NavItem>
          )}
        {commonData === "Host" &&
          roomDetailsData.roomVisibility === 1 &&
          roomDetailsData.assetType === 1 && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "invited-user",
                  "font-16 fw-normal": true,
                  "cursor-pointer": true,
                })}
                onClick={() => {
                  toggle("invited-user");
                }}
              >
                Users
              </NavLink>
            </NavItem>
          )}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "about-company",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("about-company");
            }}
          >
            Publisher
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="room-details">
          <RoomDetailsTab roomDetailsData={roomDetailsData} />
        </TabPane>
        <TabPane tabId="experience-info">
          <ExperienceInfoTab />
        </TabPane>
        {commonData !== "Public" &&
          roomDetailsData.roomVisibility === 1 &&
          roomDetailsData.assetType === 1 && (
            <TabPane tabId="user-activity">
              <UserAcitvityTab />
            </TabPane>
          )}
        {commonData === "Host" &&
          roomDetailsData.roomVisibility === 1 &&
          roomDetailsData.assetType === 1 && (
            <TabPane tabId="invited-user">
              <InvitedUserTab />
            </TabPane>
          )}
        <TabPane tabId="about-company">
          <h4 className="font-20 fw-medium invite-via-title">
            <AboutCompany data={roomDetailsData.companyDescription} />
          </h4>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default MyRoom;
