import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, FormFeedback } from "reactstrap";
/* Import images */
import logoWhiteLarge from "assets/images/logo-white-large.png";
import mailWhite from "assets/images/icons/mail-white.svg";

/* Import page specific css */
import "assets/css/pre-login-screens.css";
import PreLoginFooter from "components/PreLoginFooter/PreLoginFooter";
import { http } from "utils";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const history = useHistory();
  const validEmailRegex = /\S+@\S+\.\S+/;

  const CheckValidate = (value) => {
    const requiredMessage = "This Field is Required";
    const err = value
      ? validEmailRegex.test(value)
        ? ""
        : "Please enter a valid email."
      : requiredMessage;
    return err;
  };
  const isFormValid = () => {
    if (email) {
      return true;
    } else {
      const requiredMessage = "This Field is Required";
      const emailErrormsg = email ? error : requiredMessage;

      setError(emailErrormsg);
      return false;
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    const err = CheckValidate(event.target.value);
    setError(err);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const payload = { emailId: email };
      http("post", "forget-password", payload, false)
        .then((response) => {
          setMessage("");
          history.push("/email-sent");
        })
        .catch((error) => {
          setMessage(error?.message);
        });
    } else {
      console.log("error", error);
    }
  };

  const handleBackLogin = () => {
    setMessage("");
    history.push("/login");
  };

  return (
    <div className="pre-login-screen forgot-password">
      <div className="pre-login-inner mw-100">
        <div className="logo-white-large">
          <img alt="" src={logoWhiteLarge} />
        </div>
        <div className="pre-login-page-content-block">
          <h1 className="font-22 fw-medium page-title text-center">
            Forgot password
          </h1>
          <p className="text-center mb-4 font-14 fw-normal">
            Don't worry, you will receive an email with instructions to reset
            your password. Please enter your registered email address.
          </p>
          <form>
            <div className="form-group-wrap">
              <div className="form-group">
                <div className="input-wrap icon-right">
                  <i className="email-icon">
                    <img src={mailWhite} alt="mail-white" />
                  </i>
                  <Input
                    type="email"
                    className="input-primary font-16 fw-medium"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    invalid={Boolean(error)}
                  />
                  <FormFeedback className="font-13 fw-medium">
                    {error}
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group d-flex action-btn">
                <button
                  className="green-background-button w-100 font-16 fw-semibold"
                  title="Send Mail"
                  onClick={handleSubmit}
                >
                  Send mail
                </button>
              </div>
              <p className="font-14 fw-normal" title="back to login">
                Back to{" "}
                <span
                  className="d-inline-block link-text cursor-pointer"
                  onClick={handleBackLogin}
                  title="Login"
                >
                  Login
                </span>
              </p>
            </div>
            {message && (
              <p className="text-danger font-14 fw-normal text-center">
                {message}
              </p>
            )}
          </form>
        </div>
      </div>
      <PreLoginFooter />
    </div>
  );
}

export default ForgotPassword;
