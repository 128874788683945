import React from "react";

function AboutCompany(props) {
  return (
    <p className="font-14 fw-light text-justify">
      {props.data}
    </p>
  );
}

export default AboutCompany;
