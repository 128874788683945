import InvitedViaEmail from "./InivitedViaEmail";
import InvitedUserTable from "./InvitedUserTable";

function InvitedUserTab(props) {
  return (
    <>
      <InvitedViaEmail />
      <InvitedUserTable />
    </>
  );
}

export default InvitedUserTab;
