import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "hooks/usePagination";
import RoomList from "./RoomList";
import { updateRoomData } from "./store/explore.action";
import noInvitedUser from "assets/images/icons/no-invited-user.svg";

function RoomTab({tab}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const observer = useRef();

  const { data, hasMore, loading } = usePagination(tab, page, 10);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    dispatch(updateRoomData(data));
  }, [data, dispatch]);

  const roomList = useSelector((state) => state.explore.roomList);
  return roomList.length === 0 && loading ? (
    <div>Loading..</div>
  ) : roomList.length === 0 ? (
    <div className="no-data-block no-invited-user">
      <div className="no-data-content-block">
        <p className="font-18 fw-medium text-center">No Room Available</p>
        <p className="font-14 fw-light text-center">
          You haven’t yet created room. To create room click on the Listing Details
          tab.
        </p>
      </div>
      <div className="img-wrapper mx-auto">
        <img src={noInvitedUser} alt="" />
      </div>
    </div>
  ) : ( 
  <RoomList dataArr={roomList} lastElementRef={lastElementRef} />
  );
}

export default RoomTab;
