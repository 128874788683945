import {
  UPDATEROOM_BEGIN,
  UPDATEROOM_SUCCESS,
  UPDATEROOM_FAILURE,
  INVITEDUSERS_BEGIN,
  INVITEDUSERS_SUCCESS,
  INVITEDUSERS_FAILURE,
  ADDINVITEE_BEGIN,
  ADDINVITEE_SUCCESS,
  ADDINVITEE_FAILURE,
  DELETEINVITEE_BEGIN,
  DELETEINVITEE_SUCCESS,
  DELETEINVITEE_FAILURE,
  USERACTIVITY_BEGIN,
  USERACTIVITY_SUCCESS,
  USERACTIVITY_FAILURE,
} from "./room.constant";
import { http } from "./../../../utils/index";
import { showMessage } from "store/common.action";
import { changeCurrentCard, updateMidbarData } from "layout/Midbar/store/midbar.action";

export const updateRoomBegin = () => {
  return {
    type: UPDATEROOM_BEGIN,
  };
};

export const updateRoomSuccess = (payload) => {
  return {
    type: UPDATEROOM_SUCCESS,
    payload,
  };
};

export const updateRoomFailure = (error) => {
  return {
    type: UPDATEROOM_FAILURE,
    payload: error,
  };
};

export const addInviteeBegin = () => {
  return {
    type: ADDINVITEE_BEGIN,
  };
};

export const addInviteeSuccess = (payload) => {
  return {
    type: ADDINVITEE_SUCCESS,
    payload,
  };
};

export const addInviteeFailure = (error) => {
  return {
    type: ADDINVITEE_FAILURE,
    payload: error,
  };
};

export const invitedUsersBegin = () => {
  return {
    type: INVITEDUSERS_BEGIN,
  };
};

export const invitedUsersSuccess = (payload) => {
  return {
    type: INVITEDUSERS_SUCCESS,
    payload,
  };
};

export const invitedUsersFailure = (error) => {
  return {
    type: INVITEDUSERS_FAILURE,
    payload: error,
  };
};

export const deleteInviteeBegin = () => {
  return {
    type: DELETEINVITEE_BEGIN,
  };
};

export const deleteInviteeSuccess = (payload) => {
  return {
    type: DELETEINVITEE_SUCCESS,
    payload,
  };
};

export const deleteInviteeFailure = (error) => {
  return {
    type: DELETEINVITEE_FAILURE,
    payload: error,
  };
};

export const userActivityBegin = () => {
  return {
    type: USERACTIVITY_BEGIN,
  };
};

export const userActivitySuccess = (payload) => {
  return {
    type: USERACTIVITY_SUCCESS,
    payload,
  };
};

export const userActivityFailure = (error) => {
  return {
    type: USERACTIVITY_FAILURE,
    payload: error,
  };
};


export const updateRoom = (payload, successCB) => {
  const enterpriseId  = localStorage.getItem("enterpriseId");
  const request = http("PUT", `user/rooms/editRoom?enterpriseId=${enterpriseId}`, payload, true);
  return (dispatch, getState) => {
    const currentCard = getState().midbar.currentCard;
    const midbarData = getState().midbar.midbarData;
    dispatch(updateRoomBegin);
    request
      .then((response) => {
        successCB();
        dispatch(updateRoomSuccess(response));
        // dispatch(changeModifyFlag(true));
        const updatedData = midbarData.map((eachObj) =>
          eachObj._id === currentCard
            ? {
                ...eachObj,
                room_name: payload.roomName,
                thumbnailNumber: payload.thumbnailNumber,
              }
            : eachObj
        );
        dispatch(updateMidbarData(updatedData));
      })
      .catch((error) => {
        dispatch(updateRoomFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const deleteRoom = (id, successCB) => {
  const enterpriseId  = localStorage.getItem("enterpriseId");
  const request = http("GET", `user/rooms/deleteRoom?roomId=${id}&enterpriseId=${enterpriseId}`, {}, true);
  return (dispatch, getState) => {
    const midbarData = getState().midbar.midbarData;
    request
      .then((response) => {
        successCB();
        const updatedList = midbarData.filter(
          (eachCard) => eachCard._id !== id
        );
        dispatch(updateMidbarData(updatedList));
        dispatch(showMessage(response?.message, "info"));
        dispatch(changeCurrentCard(updatedList?.[0]?._id));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const addInvitee = (payload, successCB) => {
  const enterpriseId  = localStorage.getItem("enterpriseId");
  const request = http("POST", `user/rooms/inviteUser?enterpriseId=${enterpriseId}`, payload, true);
  return (dispatch, getState) => {
    dispatch(addInviteeBegin);
    request
      .then((response) => {
        successCB();
        const invitedUsers = getState().room.invitedUsers;
        dispatch(addInviteeSuccess(response.data));
        dispatch(invitedUsersSuccess([...invitedUsers,response.data]));
      })
      .catch((error) => {
        dispatch(addInviteeFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const invitedUsers = (id, successCB) => {
  const enterpriseId  = localStorage.getItem("enterpriseId");
  const request = http("GET", `user/rooms/getInvitedUserList/${id}?enterpriseId=${enterpriseId}`, {}, true);
  return (dispatch) => {
    dispatch(invitedUsersBegin);
    request
      .then((response) => {
        successCB();
        dispatch(invitedUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(invitedUsersFailure(error));
        // dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const deleteInvitee = (deletePlayerId, successCB) => {
  const enterpriseId  = localStorage.getItem("enterpriseId");
  const request = http("GET", `user/rooms/deleteInvitedUser/${deletePlayerId}?enterpriseId=${enterpriseId}`, {}, true);
  return (dispatch, getState) => {
    dispatch(deleteInviteeBegin);
    request
      .then((response) => {
        successCB();
        const invitedUsers = getState().room.invitedUsers;
        dispatch(showMessage(response?.message, "info"));
        const updatedList = invitedUsers.filter(
          (eachInvitee) => eachInvitee._id !== deletePlayerId
        );
        dispatch(deleteInviteeSuccess(response.data));
        dispatch(invitedUsersSuccess(updatedList));
      })
      .catch((error) => {
        console.log("delete invitee", error);
        dispatch(deleteInviteeFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const userActivity = (payload, successCB) => {
  const request = http("POST", "analytics/event_list", payload, true);
  return (dispatch) => {
    dispatch(userActivityBegin);
    request
      .then((response) => {
        successCB();
        // console.log("user acitivity success", response.event_data);
        dispatch(userActivitySuccess(response.event_data));
      })
      .catch((error) => {
        console.log("user acitivity", error);
        // dispatch(userActivityFailure(error));
        dispatch(showMessage(error?.message, "error"));
      });
  };
};
