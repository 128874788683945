import {
  CREATEROOM_BEGIN,
  CREATEROOM_SUCCESS,
  CREATEROOM_FAILURE,
  INFO_BEGIN,
  INFO_SUCCESS,
  INFO_FAILURE,
  ROOM_LIST_BEGIN,
  ROOM_LIST_SUCCESS,
  ROOM_LIST_FAILURE,
  UPDATE_ROOM_LIST,
} from "./explore.constant";

const initialState = {
  createRoomLoading: false,
  createRoomResponse: {},
  createRoomError: "",
  infoLoading: false,
  infoData: {},
  infoError: {},
  roomListLoading: false,
  roomList: [],
  roomError: "",
};

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case INFO_BEGIN:
      return {
        ...state,
        infoLoading: true,
      };
    case INFO_SUCCESS:
      return {
        ...state,
        infoLoading: false,
        infoData: action.payload,
      };
    case INFO_FAILURE:
      return {
        ...state,
        infoLoading: false,
        infoError: action.payload,
      };
    case CREATEROOM_BEGIN:
      return {
        ...state,
        createRoomLoading: true,
      };
    case CREATEROOM_SUCCESS:
      return {
        ...state,
        createRoomLoading: false,
        createRoomResponse: action.payload,
      };
    case CREATEROOM_FAILURE:
      return {
        ...state,
        createRoomLoading: false,
        createRoomError: action.payload,
      };
      case ROOM_LIST_BEGIN:
        return {
          ...state,
          roomListLoading: true,
        };
      case ROOM_LIST_SUCCESS:
        return {
          ...state,
          roomListLoading: false,
          roomList: action.payload,
        };
      case ROOM_LIST_FAILURE:
        return {
          ...state,
          roomListLoading: false,
          roomListError: action.payload,
        };

        case UPDATE_ROOM_LIST:
          return {
            ...state,
            roomListLoading: false,
            roomList: action.payload,      
          };
    default:
      return state;
  }
};

export default exploreReducer;
