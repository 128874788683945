import {
  MIDBAR_BEGIN,
  MIDBAR_SUCCESS,
  MIDBAR_FAILURE,
  UPDATE_MIDBAR,
  CURRENTCARD,
  COMMON_CARD,
} from "./midbar.constant";

const initialState = {
  midbarData: [],
  midbarErrorMessage: "",
  listLoading: true,
  currentCard: null,
  commonData : ""
};

const midbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENTCARD:
      return {
        ...state,
        currentCard: action.payload,
      }
      case COMMON_CARD:
        return {
          ...state,
          commonData: action.payload,
        };
    case MIDBAR_BEGIN:
      return {
        ...state,
        listLoading: true,
    
      };
    case MIDBAR_SUCCESS:
      return {
        ...state,
        listLoading: false,
      };
    case MIDBAR_FAILURE:
      return {
        ...state,
        // listLoading: false,
        listLoading: true,
        midbarErrorMessage: action.payload,
      };
    case UPDATE_MIDBAR:
      return {
        ...state,
          midbarData: [...action.payload],      
      };
    default:
      return state;
  }
};

export default midbarReducer;
