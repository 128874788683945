import publicWhite from "assets/images/icons/visibility-white.svg";
import privateWhite from "assets/images/icons/private-white.svg";
import unlistedWhite from "assets/images/icons/unlisted-white.svg";
import VisibilityModal from "./VisibilityModal";

function VisibilityBlock({ toggleModal, isOpen, handleVisibility, roomVisibility }) {
  const icons = [publicWhite, privateWhite, unlistedWhite];
  return (
    <div className="right-block d-flex align-items-center mb-2">
      <div className="visibility-status font-14" onClick={toggleModal}>
        <div className="visibility-icon-wrap d-inline-flex">
          <i className="visibility-icon">
            <img src={icons[roomVisibility]} alt="visibility-white" />
          </i>
        </div>
        Visibility
      </div>
      <VisibilityModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        handleVisibility={handleVisibility}
        roomVisibility={roomVisibility}
      />
    </div>
  );
}

export default VisibilityBlock;
