import React from "react";

function NoContent() {
  return (
    <div className="content-wrapper-block event-details-block flex-grow-1">
      <div className="no-data-block no-activity">
        <div className="no-data-content-block">
          <p className="font-18 fw-medium text-center">Oops! Nothing Found</p>
          <p className="font-14 fw-light text-center">
            Filtered data for this values is not available. Try changing the
            filter or search values!
          </p>
        </div>
      </div>
    </div>
  );
}

export default NoContent;
