import TabContentTopLeft from "./TabContentTopLeft";

function TabContentTop({
  roomData,
  module
}) {
  return (
    <div className="event-room-host-access-detail d-flex align-items-start">
      <TabContentTopLeft roomData={roomData} module={module} />
    </div>
  );
}

export default TabContentTop;
