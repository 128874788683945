import UserProfile from "./UserProfile";

const userProfileConfig = {
  component: UserProfile,
  path: "/userProfile",
  exact: true,
  layout: {
    config: {
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      prelogin: false,
      profilepage: true
    },
  },
  auth: true,
};

export default userProfileConfig;
