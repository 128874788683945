import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes, { AppRoute } from "core/routes";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";


/* import css */
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/main.css";
import "assets/css/style.css";
library.add(fas);

function App() {
  return (
    <Router>
      <Switch>
        {/* kick it all off with the root route */}
        <Switch>
          {routes.map((route) => (
            <AppRoute key={route.path} {...route} />
          ))}
        </Switch>
      </Switch>
    </Router>
  );
}

export default App;
