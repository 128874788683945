import React from "react";

function EachDetailsItem(props) {
  return (
    <li key={props.id}>
      <i
        className={
          props.iconClass + `${props.title ? " d-inline-block ms-0 me-2" : ""}`
        }
      >
        <img src={props.img} alt="date-time-white" />
      </i>
      <div>
        {props.title && (
          <span className="font-14 fw-thin">{props.title}</span>
        )}
        <span className="d-flex font-14 fw-thin">
          {/* {props.title && (
            <i
              className={
                props.iconClass +
                `${props.title ? " d-inline-block ms-0 me-2" : ""}`
              }
            ></i>
          )} */}
          {props.value}
          {props.title === "Status" && (
            <span
              className={`status-circle ${props.value === "Idle" ? "idle" : ""}${props.value === "DND" ? "dnd" : ""}${props.value === "In use" ? "in-use" : ""}${props.value === "Close" ? "close" : ""}`}
            ></span>
          )}
        </span>
      </div>
    </li>
  );
}

export default EachDetailsItem;
