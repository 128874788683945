/* import reactStrap components */
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import publicWhite from "assets/images/icons/visibility-white.svg";
import privateWhite from "assets/images/icons/private-white.svg";
import unlistedWhite from "assets/images/icons/unlisted-white.svg";

function VisibilityModal({
  handleVisibility,
  isOpen,
  toggleModal,
  roomVisibility,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      centered
      className="custom-modal visibility-options-modal"
    >
      <ModalBody>
        <ul className="visibility-options-list">
          <li
            className={`font-16 d-flex align-items-center ${
              roomVisibility === 0 ? "current" : ""
            }`}
            onClick={() => {
              handleVisibility(0);
            }}
          >
            <div className="visibility-icon-wrap p-0">
              <i className="visibility-icon">
                <img src={publicWhite} alt="visibility-white" />
              </i>
            </div>
            Public
          </li>
          <li
            className={`font-16 d-flex align-items-center ${
              roomVisibility === 1 ? "current" : ""
            }`}
            onClick={() => {
              handleVisibility(1);
            }}
          >
            <div className="visibility-icon-wrap p-0 ">
              <i className="visibility-icon">
                <img src={privateWhite} alt="private-white" />
              </i>
            </div>
            Private
          </li>
          <li
            className={`font-16 d-flex align-items-center ${
              roomVisibility === 2 ? "current" : ""
            }`}
            onClick={() => {
              handleVisibility(2);
            }}
          >
            <div className="visibility-icon-wrap p-0 event-list">
              <i className="visibility-icon">
                <img src={unlistedWhite} alt="unlisted-white" />
              </i>
            </div>
            Unlisted
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold green-background-button w-100"
          title="Close"
          onClick={() => toggleModal()}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default VisibilityModal;
