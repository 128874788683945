import {
  INVITEDUSERS_SUCCESS,
  USERACTIVITY_SUCCESS,
} from "./room.constant";

const initialState = {
  invitedUsers: [],
};
const roomReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITEDUSERS_SUCCESS:
      return {
        ...state,
        invitedUsers: action.payload,
      };
    case USERACTIVITY_SUCCESS:
      return {
        ...state,
        userActivities: action.payload,
      };
    default:
      return state;
  }
};

export default roomReducer;
