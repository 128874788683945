import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Scrollbars } from "react-custom-scrollbars";
import designService from "../../services/design.service";
import publishedWhite from "assets/images/icons/published-white.svg";
import lifecareWhite from "assets/images/icons/lifecare-white.svg";
import activeUserWhite from "assets/images/icons/activeuser-white.svg";
import tickWhite from "assets/images/icons/tick-white.svg";
import ImageSlider from "../../components/ImageSlider";
import RoomDetailsList from "components/RoomDetailsList/RoomDetailsList";
import TabContentTop from "components/TabContentTop.js/TabContentTop";
import { addRoom } from "./store/social.action";
import { getDateInFormat } from "utils";
import Title from "components/Title/Title";

function RoomDetailsTab({ roomData }) {
  const history = useHistory();

  const dispatch = useDispatch();
  designService.setTabContentHeight();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  const handleAddRoom = (roomId) => {
    const successCB = () => {
      history.push({ pathname: "/myroom", state: { fromSocialPage: true } });
    };
    const payload = {
      roomId: roomId,
    };
    dispatch(addRoom(payload, successCB));
  };

  const roomAnalyticsList = [
    {
      id: 0,
      iconClass: "published-icon",
      img: publishedWhite,
      title: "Created",
      value: getDateInFormat(roomData?.createdAt) || "-",
    },
    {
      id: 3,
      iconClass: "lifecare-icon",
      img: lifecareWhite,
      title: "Lifetime",
      value: roomData?.socialLifetime || "-",
    },
    {
      id: 4,
      iconClass: "activeuser-icon",
      img: activeUserWhite,
      title: "Active",
      value: roomData?.socialActive || "-",
    },
  ];

  return (
    <Scrollbars
      className="tab-pane-inner custom-scrollbar"
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="content-outer" />}
    >
      <div className="about-room mb-25">
        {Object.keys(roomData).length !== 0 && (
          <TabContentTop roomData={roomData} module="social" />
        )}
        {roomData?.bannerImages && (
          <ImageSlider data={roomData} module="social" />
        )}
      </div>
      <p className="font-14 fw-thin custom-description">
        {roomData.description}
      </p>
      <Title title="Room Analytics" />
      <RoomDetailsList list={roomAnalyticsList} />
      <div className="d-flex mt-25">
        <button
          className={`${
            roomData.roomAddedSocially
              ? "green-border-button"
              : "green-background-button"
          } font-16 fw-semibold d-flex align-items-center justify-content-center w-180px`}
          disabled={roomData.roomAddedSocially}
          onClick={() => handleAddRoom(roomData._id)}
        >
          {roomData.roomAddedSocially ? (
            <>
              Room Added
              <i className="right-arrow-icon right-icon tick-icon d-inline-block">
                <img src={tickWhite} alt="tick-white-icon" />
              </i>
            </>
          ) : (
            "Add To My Room"
          )}
        </button>
      </div>
    </Scrollbars>
  );
}

export default RoomDetailsTab;
