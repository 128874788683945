import {
  PROFILE_BEGIN,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
} from "./userProfile.constant";

const initialState = {
  profileLoading: false,
  profileDetails: {},
  profileError: "",
};
const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_BEGIN:
      return {
        ...state,
        profileLoading: true,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profileDetails: action.payload,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        profileLoading: false,
        profileError: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
