import React from "react";
// import error404Icon from "assets/images/icons/error-404-icon.svg";
import error404Icon from "assets/images/icons/error-404-new.svg";
import { Link, useHistory } from "react-router-dom";
// import error500Icon from "assets/images/icons/error-500-icon.svg";
// import error403Icon from "assets/images/icons/error-403-icon.svg";
import Logo from "assets/images/icons/logo-white.svg";
function NoMatch() {
  const history = useHistory();
  const handleHome = () => {
    history.push("/");
  };
  return (
    <div className="pre-login-screen error-screen">
      <div className="error-header">
        <img src={Logo} alt="logo" />
        <Link
          to={{ pathname: "https://viitorcloud.com/contact-us" }}
          target="_blank"
          className="font-16 fw-normal"
        >
          Contact Us
        </Link>
      </div>
      <div className="pre-login-inner w-100 d-flex align-items-center justify-content-center">
        <div className="error-404-icon">
          <img alt="" src={error404Icon} />
        </div>
        <div className="error-page-content-block d-flex">
          <div className="left-block">
            <h1 className="font-24 fw-normal page-title">404 Oops! </h1>
            <button
              className="green-border-button font-16 fw-normal d-flex align-items-center justify-content-center"
              onClick={handleHome}
            >
              Back To Home
            </button>
          </div>
          <div className="right-block">
            <h2 className="font-22 fw-normal block-title">
              It’s just a 404 error!
            </h2>
            <p className="font-14 fw-normal mb-0">
              The page you are looking for is not available.
            </p>
          </div>
        </div>
      </div>
    </div>
    /* <div className="pre-login-screen error-screen">
      <div className="pre-login-inner w-100 d-flex align-items-center justify-content-center">
        <div className="error-404-icon">
          <img alt="" src={error500Icon} />
        </div>
        <div className="error-page-content-block d-flex">
          <div className="left-block">
            <h1 className="font-24 fw-normal page-title">500 Oops! </h1>
            <button className="green-border-button font-16 fw-normal d-flex align-items-center justify-content-center">
            Try Again
            </button>
          </div>
          <div className="right-block">
            <h2 className="font-22 fw-normal block-title">Something went wrong</h2>
            <p className="font-14 fw-normal mb-0">
            We are experiencing an internal server problem or Check your internet connectivity, Please try back later.
            </p>
          </div>
        </div>
      </div>
    </div> */
    /* <div className="pre-login-screen error-screen">
      <div className="pre-login-inner w-100 d-flex align-items-center justify-content-center">
        <div className="error-404-icon">
          <img alt="" src={error403Icon} />
        </div>
        <div className="error-page-content-block d-flex">
          <div className="left-block">
            <h1 className="font-24 fw-normal page-title">403 Oops! </h1>
            <button className="green-border-button font-16 fw-normal d-flex align-items-center justify-content-center">
            Go Back
            </button>
          </div>
          <div className="right-block">
            <h2 className="font-22 fw-normal block-title">We are Sorry…..</h2>
            <p className="font-14 fw-normal mb-0">
            The page you’re trying to access has restricted access. Please refer to your system administrator
            </p>
          </div>
        </div>
      </div>
    </div> */
  );
}

export default NoMatch;
