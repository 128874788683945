export const INFO_BEGIN = "INFO_BEGIN";
export const INFO_SUCCESS = "INFO_SUCCESS";
export const INFO_FAILURE = "INFO_FAILURE";

export const CREATEROOM_BEGIN = "CREATEROOM_BEGIN";
export const CREATEROOM_SUCCESS = "CREATEROOM_SUCCESS";
export const CREATEROOM_FAILURE = "CREATEROOM_FAILURE";

export const ROOM_LIST_BEGIN = "ROOM_LIST_BEGIN";
export const ROOM_LIST_SUCCESS = "ROOM_LIST_SUCCESS";
export const ROOM_LIST_FAILURE = "ROOM_LIST_FAILURE";

export const UPDATE_ROOM_LIST = "UPDATE_ROOM_LIST";
