import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, FormFeedback } from "reactstrap";
import { useHistory } from "react-router";
import { showMessage } from "store/common.action";
import logoutWhite from "assets/images/icons/logout-white.svg";
import mailWhite from "assets/images/icons/mail-white1.svg";
import linkWhite from "assets/images/icons/link-white.svg";
import companyWhite from "assets/images/icons/company-white.svg";
import passwordWhite from "assets/images/icons/password-white1.svg";
import ShowIcon from "assets/images/icons/show-password.svg";
import HideIcon from "assets/images/icons/hidden-password.svg";
import editWhite from "assets/images/icons/edit-white.svg";
import { http } from "utils";

function UserProfileForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const profileDetails = useSelector((state) => state.profile.profileDetails);

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    showOldPassword: "",
    showNewPassword: "",
    showConfirmPassword: "",
  });

  const isFormValid = () => {
    const { oldPassword, newPassword, confirmPassword } = passwords;
    const {
      oldPassword: oldPasswordErr,
      newPassword: newPasswordErr,
      confirmPassword: confirmPasswordErr,
    } = errors;
    if (oldPassword && newPassword && confirmPassword) {
      if (!oldPasswordErr && !newPasswordErr && !confirmPasswordErr) {
        return true;
      } else {
        return false;
      }
    } else {
      const requiredMessage = "This Field is Required";
      const oldErrormsg = oldPassword ? oldPasswordErr : requiredMessage;
      const newPasswordErrormsg = newPassword
        ? newPasswordErr
        : requiredMessage;
      const confirmPasswordErrormsg = confirmPassword
        ? confirmPasswordErr
        : requiredMessage;
      setErrors({
        oldPassword: oldErrormsg,
        newPassword: newPasswordErrormsg,
        confirmPassword: confirmPasswordErrormsg,
      });
      return false;
    }
  };
  const CheckValidate = (name, value) => {
    const requiredMessage = "This Field is Required";
    if(name==="newPassword" && passwords.confirmPassword){
      const err = value ?  value === passwords.confirmPassword ? "": "Password and confirm password should be same" :requiredMessage;
      return err;
    }
    switch (name) {
      case "oldPassword": {
        const err = value ? "" : requiredMessage;
        return err;
      }
      case "newPassword": {
        const err = value
          ? value.length >= 8 &&
            /(?=.*?[a-z])/.test(value) &&
            /(?=.*?[A-Z])/.test(value) &&
            /\d/.test(value) &&
            /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value)
            ? passwords.confirmPassword ? passwords.confirmPassword === value ? "" : "" : ""
            : "Password must contain at least 8 characters, 1 upper case letter,1 lower case letter (a-z),1 number and 1 special character"
          : requiredMessage;
        return err;
      }
      case "confirmPassword": {
        const err = value
          ? value === passwords.newPassword
            ? ""
            : "Password and confirm password should be same"
          : requiredMessage;
        return err;
      }
      default: {
        return "";
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const err = CheckValidate(name, value);
    setPasswords({ ...passwords, [name]: value });
    const key = name ==="newPassword" && passwords.confirmPassword ? "confirmPassword" : [name]
    setErrors({ ...errors,[key]: err });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      const payload = {
        oldPassword: passwords.oldPassword,
        password: passwords.newPassword,
      };
      http("post", "profile/reset/password", payload, true)
        .then((response) => {
          setPasswords({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          dispatch(showMessage(response?.message, "info"));
        })
        .catch((error) => dispatch(showMessage(error?.message, "error")));
      setShow(false);
    } else {
      console.log("error", errors);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setShowPasswords({
      showOldPassword: "",
      showNewPassword: "",
      showConfirmPassword: "",
    });
    setShow(false);
  };
  const handleEdit = () => {
    setShow(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };
  return (
    <>
      <div className="user-profile-detail">
        <div className="upper-block">
          <div className="d-flex align-items-center mb-20">
            <div className="organization-profile">
              {profileDetails.organizationLogo ? (
                <img src={profileDetails.organizationLogo} alt="org-logo" width="100%" height="100%" />
              ) : (
                <h3 className="font-28 fw-semibold text-uppercase mb-0">
                  {profileDetails?.organizationName?.[0]}
                </h3>
              )}
            </div>
            <h2 className="font-18 fw-medium block-title mb-0">Organization Information</h2>
          </div>
          <ul className="company-info-listing">
            <li className="d-flex position-relative align-items-start">
              <i className="list-icon">
                <img src={companyWhite} alt="company-white" />
              </i>
              <div className="font-14 fw-medium opacity-80 list-label">
                Organization Name
              </div>
              <span className="font-16 fw-medium">
                {profileDetails.organizationName || "-"}
              </span>
            </li>
            <li className="d-flex position-relative align-items-start">
              <i className="list-icon">
                <img src={linkWhite} alt="link-white" />
              </i>
              <div className="font-14 fw-medium opacity-80 list-label">
                Website
              </div>
              <a
                href={`//${profileDetails.websiteUrl || "#"}`}
                rel="noopener noreferrer"
                target="_blank"
                title={profileDetails.websiteUrl || ""}
                className="font-16 fw-medium color-blue"

              >
                {profileDetails.websiteUrl || "-"}
              </a>
            </li>
            <li className="d-flex position-relative align-items-start">
              <i className="list-icon">
                <img src={mailWhite} alt="mail-white" />
              </i>
              <div className="font-14 fw-medium opacity-80 list-label">
                Email Id
              </div>
              <a
                href={`mailto:${profileDetails.emailId || ""}`}
                title={profileDetails.emailId || ""}
                className="font-16 fw-medium email-address text-white"
              >
                {profileDetails.organizationEmail || ""}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="user-profile-detail">
        <div className="upper-block">
          <h2 className="font-18 fw-medium block-title">
            Change Password &nbsp;&nbsp;
            <i className="edit-icon" onClick={handleEdit}>
              <img src={editWhite} alt="edit-white" />
            </i>
          </h2>
          <div className="change-password-form-wrap d-flex align-items-start">
            <i className="password-icon">
              <img src={passwordWhite} alt="password-white" />
            </i>
            <form className="change-password-form">
              <div className="form-group-wrap">
                {!show ? (
                  <div className="form-group password-value">
                    <p className="font-20 w-100 mb-0">**********</p>
                  </div>
                ) : (
                  <>
                    <div className="form-group input-wrap icon-right change-pwd">
                     
                        <i className="lock-icon">
                          <img
                            src={ showPasswords.showOldPassword ? ShowIcon:HideIcon}
                            alt="show-password"
                            onClick={() =>
                              setShowPasswords({
                                ...showPasswords,
                                showOldPassword: !showPasswords.showOldPassword,
                              })
                            }
                          />
                        </i>
          
                      <Input
                        type={
                          showPasswords.showOldPassword ? "text" : "password"
                        }
                        placeholder="Old Password *"
                        className="form-control primary-input font-13 fw-medium"
                        name="oldPassword"
                        value={passwords.oldPassword}
                        onChange={handleChange}
                        invalid={Boolean(errors.oldPassword)}
                      />
                      <FormFeedback className="font-13 fw-normal">
                        {errors.oldPassword}
                      </FormFeedback>
                    </div>
                    <div className="form-group input-wrap icon-right change-pwd">
                        <i className="lock-icon">
                          <img
                            src={showPasswords.showNewPassword ? ShowIcon:HideIcon}
                            alt="show-password"
                            onClick={() =>
                              setShowPasswords({
                                ...showPasswords,
                                showNewPassword: !showPasswords.showNewPassword,
                              })
                            }
                          />
                        </i>
                      <Input
                        type={
                          showPasswords.showNewPassword ? "text" : "password"
                        }
                        placeholder="Password *"
                        className="form-control primary-input font-13 fw-medium"
                        name="newPassword"
                        value={passwords.newPassword}
                        onChange={handleChange}
                        invalid={Boolean(errors.newPassword)}
                      />
                      <FormFeedback className="font-13 fw-normal">
                        {errors.newPassword}
                      </FormFeedback>
                    </div>
                    <div className="form-group input-wrap icon-right change-pwd">
                        <i className="lock-icon">
                          <img
                            src={showPasswords.showConfirmPassword ? ShowIcon:HideIcon }
                            alt="show-password"
                            onClick={() =>
                              setShowPasswords({
                                ...showPasswords,
                                showConfirmPassword: !showPasswords.showConfirmPassword,
                              })
                            }
                          />
                        </i>
                      <Input
                        type={
                          showPasswords.showConfirmPassword
                            ? "text"
                            : "password"
                        }
                        placeholder="Confirm Password *"
                        className="form-control primary-input font-13 fw-medium"
                        name="confirmPassword"
                        value={passwords.confirmPassword}
                        onChange={handleChange}
                        invalid={Boolean(errors.confirmPassword)}
                      />
                      <FormFeedback className="font-13 fw-normal">
                        {errors.confirmPassword}
                      </FormFeedback>
                    </div>
                    <div className="d-flex justify-content-between action-btns">
                      <button
                        className="green-background-button font-14 fw-semibold"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                      <button
                        className="green-border-button font-14 fw-semibold"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="user-profile-detail">
        <div className="upper-block" onClick={handleLogout} style={{ cursor: "pointer" }}>
          <ul className="company-info-listing">
            <li className="d-flex position-relative align-items-start">
              <i className="list-icon">
                <img src={logoutWhite} alt="logout-white" />
              </i>
              <div className="font-14 fw-medium opacity-80 list-label">
                Logout
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="update-data-form-wrap"></div>
    </>
  );
}

export default UserProfileForm;
