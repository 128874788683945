import { ADDROOM_SUCCESS } from "./social.constant";

const initialState = {
  addRoomResponse: {},
};
const socialReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDROOM_SUCCESS:
      return{
        ...state,
        addRoomResponse: action.payload,
      }
    default:
      return state;
  }
};

export default socialReducer;