import UserDetail from "./UserDetail";
import "assets/css/profile-page.css";
import UserProfileForm from "./UserProfileForm";

function UserProfile() {
  return (
    <div className="content-wrapper-block visible-mobile">
      <div className="content-title-wrapper d-flex flex-wrap align-items-center">
        <h1 className="font-22 fw-semibold title-text">Profile</h1>
      </div>
      <UserDetail />
      <UserProfileForm />
    </div>
  );
}

export default UserProfile;
