import React, { useState } from "react";
import { FormFeedback, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
function FeedbackModal({
  handlefeedBack,
  isOpen,
  toggle,
  feedback,
  setFeedback,
}) {
  const [error, setError] = useState("");

  const CheckValidate = (value) => {
    const requiredMessage = "This Field is Required";
    const err = value
        ? ""
      : requiredMessage;
    return err;
  };
  const isFormValid = () => {
  if(feedback.review){
    if (!error) {
      return true;
    } 
  }
    else {
      const errorMsg = feedback.review ? error : "This Field is Required";
      setError(errorMsg);
      return false;
    }
  };


  const handleChange = (name, value) => {
    setFeedback({ ...feedback, [name]: value });
    if(name === "review" ){
      const err = CheckValidate(value);
      setError(err);
    }
   
  };
  const handleClose = () => {
    toggle();
    setFeedback({ review: "", ratings: "3" });
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="custom-modal feedback-modal"
    >
      <ModalBody>
        <form>
          <div className="form-group-wrap">
            <div className="form-group">
              <label className="font-16 fw-normal">Write a review</label>
              <Input
                type="textarea"
                className="form-control secondary-input font-18 fw-medium"
                placeholder="Enter your review"
                onChange={(e) => {
                  handleChange("review", e.target.value);
                }}
                value={feedback.review}
                invalid={Boolean(error)}
              />
                <FormFeedback>{error}</FormFeedback>
            </div>
            <div className="form-group">
              <label className="font-16 fw-normal">Ratings</label>
              <div id="full-stars-example">
                <div className="rate">
                  {["5", "4", "3", "2", "1"].map((rating) => {
                    return (
                      <React.Fragment key={rating}>
                        <input
                          type="radio"
                          id={`star${+rating}`}
                          name="rate"
                          value={rating}
                          checked={rating === feedback.ratings}
                          onChange={(e) => {
                            handleChange("ratings", e.target.value);
                          }}
                        />
                        <label htmlFor={`star${+rating}`} title="text">
                          {+rating} stars
                        </label>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold gray-background-button"
          title="Close"
          onClick={handleClose}
        >
          Close
        </button>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold green-background-button"
          title="Submit"
          onClick={() => {
            if(isFormValid()){
              handlefeedBack(feedback)}else {
                console.log("solve error");
              }}
            }
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default FeedbackModal;
