import React from "react";
import { useSelector } from "react-redux";
import DesktopIcon from "assets/images/icons/desktop-icon.svg";
import PhoneIcon from "assets/images/icons/phone-icon.svg";
import BrowserIcon from "assets/images/icons/browser-icon.svg";
import OculusIcon from "assets/images/icons/oculus-icon.svg";
import MultiUser from "assets/images/icons/multi-user-icon.svg";
import SingleUser from "assets/images/icons/single-user-icon.svg";
import { Tooltip } from "reactstrap";


function TabContentTopLeft({ roomData, module }) {
  const { commonData } = useSelector(
    ({ midbar }) => midbar
  );
  const assetType = [SingleUser, MultiUser];
  const [tooltip, settooltip] = React.useState({ desktopContent: false, phoneContent: false, oculusContent: false, webContent: false });
  return (
    <div className="left-block">
      <div className="d-flex align-items-center flex-wrap">
        <h4 className="font-14 fw-thin opacity-90 created-by">Created by</h4>
        <h4 className="font-16 fw-normal creator">{roomData.hostName}</h4>
        {module !== "social" ? (
          <div
            className={`badge role-badge font-13 fw-thin me-3
            ${commonData === "Host" ? "red" : "blue"}`
            }
          >
            {commonData}
          </div>
        ) : (
          <div className={`badge role-badge font-13 fw-thin me-3 blue`}>
            Public
          </div>
        )}
        <div className="player-type d-flex align-items-center me-3">
          <i className="user-type d-inline-block me-2">
            <img src={assetType[roomData.assetType]} alt="multi-user" />
          </i>
        </div>
        <div className="d-flex align-items-center me-2">
          {roomData.platform && (
            <div className="device-type-listing d-flex align-items-center">
              {(roomData.platform.includes(0) ||
                roomData.platform.includes(3)) && (
                  <>
                    <i className="d-inline-block me-1" id={`desktopContent`}>
                      <img src={DesktopIcon} alt="desktop-icon" />
                    </i>
                    <Tooltip
                      isOpen={tooltip.desktop}
                      placement="bottom"
                      target={`desktopContent`}
                      style={{ fontSize: 12 }}
                      toggle={() => settooltip({ ...tooltip, desktop: !tooltip.desktop })}
                    >
                      Desktop
                    </Tooltip>
                  </>
                )}
              {(roomData.platform.includes(1) ||
                roomData.platform.includes(2)) && (
                  <>
                    <i className="d-inline-block me-1" id={`phoneContent`}>
                      <img src={PhoneIcon} alt="phone" />
                    </i>
                    <Tooltip
                      isOpen={tooltip.phone}
                      placement="bottom"
                      target={`phoneContent`}
                      style={{ fontSize: 12 }}
                      toggle={() => settooltip({ ...tooltip, phone: !tooltip.phone })}
                    >
                      Phone
                    </Tooltip>
                  </>
                )}
              {roomData.platform.includes(4) && (
                <>
                  <i className="d-inline-block me-1" id={`oculusContent`}>
                    <img src={OculusIcon} alt="oculus" />
                  </i>
                  <Tooltip
                    isOpen={tooltip.oculus}
                    placement="bottom"
                    target={`oculusContent`}
                    style={{ fontSize: 12 }}
                    toggle={() => settooltip({ ...tooltip, oculus: !tooltip.oculus })}
                  >
                    Oculus
                  </Tooltip>
                </>
              )}
              {roomData.platform.includes(5) && (
                <>
                  <i className="d-inline-block me-1" id={`webContent`}>
                    <img src={BrowserIcon} alt="browser" />
                  </i>
                  <Tooltip
                    isOpen={tooltip.browser}
                    placement="bottom"
                    target={`webContent`}
                    style={{ fontSize: 12 }}
                    toggle={() => settooltip({ ...tooltip, browser: !tooltip.browser })}
                  >
                    Web
                  </Tooltip>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TabContentTopLeft;
