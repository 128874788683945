import Explore from "./Explore";

const exploreConfig = {
  component: Explore,
  path: "/explore",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      prelogin: false
    },
  },
  auth: true,
};

export default exploreConfig;
