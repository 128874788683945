import React from "react";
import { Link } from "react-router-dom";
import desktopWhite from "assets/images/icons/desktop-white.svg";
import androidWhite from "assets/images/icons/android-white.svg";
import appleWhite from "assets/images/icons/apple-white.svg";

function PreLoginFooter() {
  return (
    <div className="footer-block position-absolute d-flex justify-content-between align-items-center w-100">
      <Link
        to={{ pathname: "https://viitorcloud.com/contact-us" }}
        target="_blank"
        className="font-14 fw-normal mb-0 w-100"
        style={{ color: "white" }}
      >
        Copyright © 2021 Real Immerse | About | Help
      </Link>
      <ul className="social-media-listing d-flex align-items-center">
        <li className="me-2">
          <Link to="#" title="Desktop" className="desktop-icon d-block">
            <img
              src={desktopWhite}
              alt="desktop-white"
              className="w-100 h-100"
            />
          </Link>
        </li>
        <li className="me-2">
          <Link to="#" title="Android" className="android-icon d-block">
            <img
              src={androidWhite}
              alt="android-white"
              className="w-100 h-100"
            />
          </Link>
        </li>
        <li>
          <Link to="#" title="Apple" className="apple-icon d-block">
            <img src={appleWhite} alt="apple-white" className="w-100 h-100" />
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default PreLoginFooter;
