import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import "assets/css/organization-screen.css";
import logoWhiteLarge from "assets/images/logo-white-large.png";
import { fetchOrganizationList } from "./store/organization.action";
import { showMessage } from "store/common.action";
import { getFormattedText } from "utils";

function OrganizationSelection() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [orgId, setOrgId] = useState(null);

  useEffect(() => {
    const enterpriseId = localStorage.getItem("enterpriseId");
    const token = localStorage.getItem("userToken");
    if (!token) {
      history.push("/login");
    } else if (token && enterpriseId) {
      history.push("/");
    }
    const successCB = (id) => {
      setOrgId(id);
    };
    dispatch(fetchOrganizationList(successCB));
  }, [dispatch, history]);

  const { organizationList } = useSelector(({ organization }) => organization);
  const { name, emailId, profileImage } = JSON.parse(
    localStorage.getItem("userDetails")
  );

  const handleContinue = () => {
    if (orgId) {
      localStorage.setItem("enterpriseId", orgId);
      history.push("/");
    } else {
      dispatch(showMessage("Unlicenced User", "error"));
    }
  };

  return (
    <div className="organization-block">
      <div className="organization-inner mw-100">
        <div className="logo-white-large">
          <img alt="real-immerse-logo" src={logoWhiteLarge} />
        </div>
        <div className="organization-content-block">
          <h1 className="font-22 fw-medium page-title text-center mb-4">
            Choose your organization
          </h1>
          <div className="inner-content">
            <div className="organize selected">
              <div className="profile">
                {profileImage ? (
                  <img src={profileImage} alt="vc" />
                ) : (
                  <p className="profile-letter fw-semibold m-0">
                    {name?.charAt(0)}
                  </p>
                )}
              </div>
              <div className="profile-name">
                <p className="name">{getFormattedText(name, 30) || "-"}</p>
                <p className="email">{getFormattedText(emailId, 32) }</p>
              </div>
              <p className="d-block right-content mb-0 font-14">YourSelf</p>
            </div>
            <p className="my-3 font-18">Organization list</p>
            <Scrollbars
              className="custom-scrollbar"
              style={{ height: "230px" }}
              renderThumbHorizontal={(props) => (
                <div {...props} className="thumb-horizontal" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              renderView={(props) => (
                <div {...props} className="content-outer" />
              )}
            >
              <div className="organizers pb-3">
                {organizationList.map(({ _id, name, img, isLicenceUsed }) => (
                  <button
                    key={_id}
                    className={`organize w-100 ${orgId === _id && "selected"}`}
                    disabled={isLicenceUsed === 0}
                    onClick={() => setOrgId(_id)}
                  >
                    <div className="profile">
                      {img ? (
                        <img src={img} alt="org-img" />
                      ) : (
                        <p className="profile-letter fw-semibold m-0">
                          {name?.charAt(0)}
                        </p>
                      )}
                    </div>
                    <div className="profile-name">
                      <p className="name">{getFormattedText(name, 30)}</p>
                    </div>
                    <p className="d-block right-content mb-0 font-14">
                      {isLicenceUsed === 1 ? "(Licenced)" : "(Unlicenced)"}
                    </p>
                  </button>
                ))}
              </div>
            </Scrollbars>
            <button
              className="green-background-button w-100 font-16 fw-semibold mt-3"
              title="continue"
              onClick={handleContinue}
            >
              Continue
            </button>
            <p className="mt-4 mb-0 text-center">
              Back to{" "}
              <Link to="/login" className="link">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationSelection;
