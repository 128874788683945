function AboutRoom(props) {
  return (
    <div className="about-room-content">
      <p className="font-14 fw-thin custom-description">
       {props.roomDetails}
      </p>
    </div>
  );
}

export default AboutRoom;
