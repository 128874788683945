import Social from "./Social";

const socialConfig = {
  component: Social,
  path: "/social",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      prelogin: false
    },
  },
  auth: true,
};

export default socialConfig;
