import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSearch from "hooks/useSearch";
import { updateMidbarData } from "./store/midbar.action";
import SearchBar from "./SearchBar";
import AllListings from "./AllListings";
import CardsList from "./CardsList";

function Midbar({ display }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const observer = useRef();
  const currentPanel = location.pathname.substring(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("descDate");
  const [duration, setDuration] = useState(50);
  const [userType, setUserType] = useState(2);

  const getInitialState = () => {
    const redirectData = JSON.parse(localStorage.getItem("redirectData"));
    if (location?.state?.fromSocialPage) {
      return "Public";
    } else if (redirectData?.flag) {
      if (redirectData?.redirectObj?.card === "Host") {
        return "Host";
      } else if (redirectData?.redirectObj?.card === "Invited") {
        return "Invited";
      } else if (redirectData?.redirectObj?.card === "Public") {
        return "Public";
      }
    } else {
      return "All Rooms";
    }
  };
  const [roomTypeFilter, setroomTypeFilter] = useState(getInitialState());

  const { data, hasMore, loading } = useSearch(
    currentPanel,
    roomTypeFilter,
    search,
    sort,
    duration,
    userType,
    page,
    10
  );

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    dispatch(updateMidbarData(data));
  }, [data, dispatch]);

  if (!display) return null;

  const getTitle = () => {
    switch (currentPanel) {
      case "explore":
        return "Explore";
      case "myroom":
        return "My Room";
      case "social":
        return "Social";
      default:
        return "My Room";
    }
  };

  return (
    <div className={`search-filter-block hidden-ipad-mobile ${currentPanel}`}>
      <SearchBar
        page={page}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />
      <div className="hidden-ipad-mobile">
        <div className="d-flex justify-content-between align-items-center flex-wrap explore-filter">
          <h1 className="font-22 fw-normal title-text">{getTitle()}</h1>
          <AllListings
            module={currentPanel}
            setroomTypeFilter={setroomTypeFilter}
            roomTypeFilter={roomTypeFilter}
            setPage={setPage}
            setSort={setSort}
            setDuration={setDuration}
            setUserType={setUserType}
          />
        </div>
        <CardsList module={currentPanel} lastElementRef={lastElementRef} />
      </div>
    </div>
  );
}

export default React.memo(Midbar);