import Dashboard from "./Dashboard";

const dashboardConfig = {
  component: Dashboard,
  path: "/",
  exact: true,
  layout: {
    config: {
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      prelogin: false,
      dashboardpage: true,
    },
  },
  auth: true,
};

export default dashboardConfig;
