import Settings from "./Settings";

const settingsConfig = {
  component: Settings,
  path: "/settings",
  exact: true,
  layout: {
    config: {
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      prelogin: false,
      settingspage: true
    },
  },
  auth: true,
};

export default settingsConfig;
