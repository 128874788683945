import React from "react";
import { useHistory } from "react-router-dom";
/* Import images */
import logoWhiteLarge from "assets/images/logo-white-large.png";
import changePasswordLogo from "assets/images/icons/change-password-logo.svg";

/* Import page specific css */
import "assets/css/pre-login-screens.css";
import PreLoginFooter from "components/PreLoginFooter/PreLoginFooter";

function PasswordChanged() {
  const history = useHistory();
  const handleLogin = () => {
    localStorage.clear();
    history.push("/login");
  }
  return (
    <div className="pre-login-screen password-changed">
      <div className="pre-login-inner">
        <div className="logo-white-large">
          <img alt="" src={logoWhiteLarge} />
        </div>
        <div className="pre-login-page-content-block">
          <i className="change-password-logo d-block mx-auto mb-4">
            <img src={changePasswordLogo} alt="change-password-logo" />
          </i>
          <p className="font-22 fw-medium mb-3 text-center">
            Password Changed Successfully!
          </p>
          <p className="font-16 fw-normal mb-4 text-center opacity-80">
            Please login to your email account again
          </p>
          <button
            className="green-background-button w-100 font-16 fw-normal mb-3"
            title="Login Now"
            onClick={handleLogin}
          >
            Login Now
          </button>
        </div>
      </div>
      <PreLoginFooter />
    </div>
  );
}
export default PasswordChanged;
