import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetchInfo } from "./store/explore.action";
import ListingDetailsTab from "./ListingDetailsTab";
import backArrowWhite from "assets/images/icons/back-arrow-white.svg";
import AboutCompany from "components/AboutCompany";
import designService from "../../services/design.service";
import ContentSkeleton from "components/Skeletons/ContentSkeleton";
import NoContent from "components/NoContent";
import RoomTab from "./RoomTab";

function Explore() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("listing-details");

  const { midbarData, listLoading, currentCard } = useSelector(
    ({ midbar }) => midbar
  );
  const { infoLoading, infoData } = useSelector(({ explore }) => explore);

  useEffect(() => {
    const successCB = () => {
      designService.setTabContentHeight();
    };
    currentCard && dispatch(fetchInfo(currentCard, successCB));
  }, [currentCard, dispatch]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    toggle("listing-details");
    // eslint-disable-next-line
  }, [currentCard]);

  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  return midbarData.length === 0 && !infoLoading && !listLoading ? (
    <NoContent />
  ) : midbarData.length === 0 || infoLoading ? (
    <ContentSkeleton />
  ) : (
    <div
      className="content-wrapper-block event-details-block flex-grow-1"
      style={{ minWidth: "0" }}
    >
      <div className="event-title-block d-flex justify-content-between">
        <div className="content-title-wrapper d-flex flex-wrap align-items-center">
          <i
            className="back-arrow-icon"
            onClick={() => designService.toggleDetails()}
          >
            <img src={backArrowWhite} alt="back-arrow-white" />
          </i>
          <h3 className="font-22 fw-semibold event-title">{infoData.name}</h3>
          <h4
            className="font-16 fw-normal text-blue"
            onClick={() => {
              window.open(`https://uat.realimmerse.app/publisher/${infoData.publisherId}`, "_blank");
            }}
          >
            {infoData.publisherName}
          </h4>
        </div>
      </div>
      <Nav tabs className="primary-tab-nav d-flex">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "listing-details",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("listing-details");
            }}
          >
            Info.
          </NavLink>
        </NavItem>
        {infoData.assetType !== 0 && (
          <>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "host-list",
                  "font-16 fw-normal": true,
                  "cursor-pointer": true,
                })}
                onClick={() => {
                  toggle("host-list");
                }}
              >
                Hosted
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "invite-list",
                  "font-16 fw-normal": true,
                  "cursor-pointer": true,
                })}
                onClick={() => {
                  toggle("invite-list");
                }}
              >
                Invited
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "public-list",
                  "font-16 fw-normal": true,
                  "cursor-pointer": true,
                })}
                onClick={() => {
                  toggle("public-list");
                }}
              >
                Public
              </NavLink>
            </NavItem>
          </>
        )}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "about-company",
              "font-16 fw-normal": true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              toggle("about-company");
            }}
          >
            Publisher
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="listing-details" className="listing-details-tab">
          <ListingDetailsTab infoData={infoData} />
        </TabPane>
        {infoData.assetType !== 0 && (
          <>
            <TabPane tabId="host-list">
              {activeTab === "host-list" && <RoomTab tab="hosted" />}
            </TabPane>
            <TabPane tabId="invite-list">
              {activeTab === "invite-list" && <RoomTab tab="invited" />}
            </TabPane>
            <TabPane tabId="public-list">
              {activeTab === "public-list" && <RoomTab tab="public" />}
            </TabPane>
          </>
        )}
        <TabPane tabId="about-company">
          <AboutCompany data={infoData.companyDescription} />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Explore;
