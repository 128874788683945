import React from "react";
import DesktopIcon from "assets/images/icons/desktop-icon.svg";
import PhoneIcon from "assets/images/icons/phone-icon.svg";
import BrowserIcon from "assets/images/icons/browser-icon.svg";
import OculusIcon from "assets/images/icons/oculus-icon.svg";
import MultiUser from "assets/images/icons/multi-user-icon.svg";
import SingleUser from "assets/images/icons/single-user-icon.svg";
// import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { changeRedirectFlag } from "./store/explore.action";
import {
  // changeCurrentCard,
  // updateMidbarData,
} from "layout/Midbar/store/midbar.action";
import { Tooltip } from "reactstrap";

function NewEachCard(props) {
  // const history = useHistory();
  // const dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = React.useState({ desktop: false, phone: false, oculus: false, web: false });
  const assetType = [SingleUser, MultiUser];

  // const cardClicked = (data) => {
  //   dispatch(updateMidbarData([]));
  //   dispatch(changeCurrentCard(null));
  //   localStorage.setItem(
  //     "redirectData",
  //     JSON.stringify({ flag: true, redirectObj: data })
  //   );
  //   history.push("/myroom");
  //   // dispatch(changeRedirectFlag(true, data, "myroom"));
  // };

  return (
    <li
      className="d-flex room-list"
      onClick={() => {
        // cardClicked(props);
      }}
      ref={props.cardRef}
    >
      <div className="img-wrap">
        <img src={props.imageURL} alt={props.room_name} />
      </div>
      <div className="text-wrap">
        <h2 className="font-14 block-title fw-normal">{props.room_name}</h2>
        <p className="font-10 fw-light organizer">{props.publisher}</p>
        <div className="badge category-label font-10 fw-thin green">
          {props.asset_category}
        </div>
        {props.card ? (
          <div
            className={`badge role-badge font-13 fw-thin ${props.card === "Host" ? "red" : "blue"
              }`}
          >
            {props.card}
          </div>
        ) : null}
        <div className="d-flex align-items-center mt-15">
          <i className="user-type d-inline-block me-3">
            <img src={assetType[props.asset_type]} alt="multi-user" />
          </i>
          <div className="device-type-listing d-flex align-items-center">
            {(props.asset_platforms.includes(0) ||
              props.asset_platforms.includes(3)) && (
                <>
                  <i className="d-inline-block me-1" id={`desktop`}>
                    <img src={DesktopIcon} alt="desktop-icon" />
                  </i>
                  <Tooltip
                    isOpen={tooltipOpen.desktop}
                    placement="bottom"
                    target={`desktop`}
                    toggle={() => setTooltipOpen({ ...tooltipOpen, desktop: !tooltipOpen.desktop })}
                  >
                    Desktop
                  </Tooltip>
                </>
              )}
            {(props.asset_platforms.includes(1) ||
              props.asset_platforms.includes(2)) && (
                <>
                  <i className="d-inline-block me-1" id={`phone`}>
                    <img src={PhoneIcon} alt="phone" />
                  </i>
                  <Tooltip
                    isOpen={tooltipOpen.phone}
                    placement="bottom"
                    target={`phone`}
                    toggle={() => setTooltipOpen({ ...tooltipOpen, phone: !tooltipOpen.phone })}
                  >
                    Phone
                  </Tooltip>
                </>
              )}
            {props.asset_platforms.includes(4) && (
              <>
                <i className="d-inline-block me-1" id={`oculus`}>
                  <img src={OculusIcon} alt="oculus" />
                </i>
                <Tooltip
                  isOpen={tooltipOpen.oculus}
                  placement="bottom"
                  target={`oculus`}
                  toggle={() => setTooltipOpen({ ...tooltipOpen, oculus: !tooltipOpen.oculus })}
                >
                  Oculus
                </Tooltip>
              </>
            )}
            {props.asset_platforms.includes(5) && (
              <>
                <i className="d-inline-block me-1" id={`web`}>
                  <img src={BrowserIcon} alt="browser" />
                </i>
                <Tooltip
                  isOpen={tooltipOpen.browser}
                  placement="bottom"
                  target={`web`}
                  toggle={() => setTooltipOpen({ ...tooltipOpen, browser: !tooltipOpen.browser })}
                >
                  Web
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

export default NewEachCard;
