import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import NewEachCard from "./NewEachCard";

function RoomList({ dataArr, lastElementRef }) {
  console.log("dataArr", dataArr);
  return (
    <Scrollbars
      className="events-list custom-scrollbar"
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="content-outer" />}
    >
      <ul className="d-flex flex-wrap justify-content-between">
        {dataArr.map(
          (
            {
              _id,
              bannerImage,
              thumbnailNumber,
              roomName,
              asset_publisher_name,
              asset_Category,
              assetType,
              asset_platforms,
            },
            index
          ) => {
            return (
              <NewEachCard
                cardRef={
                  dataArr.length - 1 === index + 1 ? lastElementRef : null
                }
                key={_id}
                currentId={_id}
                imageURL={bannerImage[thumbnailNumber]}
                room_name={roomName}
                publisher={asset_publisher_name}
                asset_category={asset_Category}
                // card={card}
                asset_type={assetType}
                asset_platforms={asset_platforms}
              />
            );
          }
        )}
      </ul>
    </Scrollbars>
  );
}

export default RoomList;
