import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { Spinner } from "reactstrap";
import EachCard from "./EachCard";
import designService from "services/design.service";
import CardSkeleton from "components/Skeletons/CardSkeleton";


function CardsList(props) {
  const { listLoading, midbarData } = useSelector(({ midbar }) => midbar);

  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  if (midbarData === undefined) {
    return null;
  }
  const { module } = props;

  return midbarData.length === 0 && !listLoading ? (
    <div className="events-list custom-scrollbar">
      <p className="nothing-found"> Nothing Found</p>
    </div>
  ) : listLoading && midbarData.length === 0 ? (
    <CardSkeleton />
  ) : (
    <Scrollbars
      className="events-list custom-scrollbar"
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="content-outer" />}
    >
      <ul>
        {midbarData?.map(
          (
            {
              assetId,
              _id,
              asset_thumbnail_url,
              bannerImage,
              thumbnailNumber,
              asset_name,
              room_name,
              asset_publisher_name,
              asset_category,
              userType,
              assetType,
              asset_platforms,
            },
            index
          ) => {
            return (
              <EachCard
                cardRef={
                  midbarData.length - 1 === index + 1
                    ? props.lastElementRef
                    : null
                }
                key={module === "explore" ? assetId : _id}
                currentId={module === "explore" ? assetId : _id}
                imageURL={
                  module === "explore"
                    ? asset_thumbnail_url
                    : bannerImage[thumbnailNumber]
                }
                name={module === "explore" ? asset_name : room_name}
                publisher={asset_publisher_name}
                category={asset_category}
                badge={
                  module === "explore"
                    ? null
                    : module === "myroom"
                    ? userType
                    : "Public"
                }
                module={module}
                asset_type={assetType}
                asset_platforms={asset_platforms}
              />
            );
          }
        )}
      </ul>
      {listLoading && midbarData.length !== 0 && <Spinner color="light" />}
    </Scrollbars>
  );
}

export default CardsList;
