import { ORGANIZATION_BEGIN, ORGANIZATION_SUCCESS, ORGANIZATION_FAILURE, ORGANIZATION_SWITCH } from "./organization.constant";
import { http } from "utils/index";

export const organizationBegin = () => {
  return {
    type: ORGANIZATION_BEGIN,
  };
};

export const organizationSuccess = (data) => {
  return {
    type: ORGANIZATION_SUCCESS,
    payload: data,
  };
};

export const organizationFailure = (error) => {
  return {
    type: ORGANIZATION_FAILURE,
  };
};

export const organizationSwitch = (data) => {
  return {
    type: ORGANIZATION_SWITCH,
    payload: data,
  };
};

export const fetchOrganizationList = (successCB) => {
  const request = http("GET", "enterprise/list", {}, true);
  return (dispatch) => {
    dispatch(organizationBegin());
    request
      .then((response) => {
        dispatch(organizationSuccess(response?.data));
        const foundLincencedUser = response?.data?.find(({isLicenceUsed}) => isLicenceUsed === 1);
        successCB(foundLincencedUser?._id);
      })
      .catch((error) => {
        dispatch(organizationFailure(error));
      });
  };
};
