import { http } from "utils";
import {
  SHOW_MESSAGE,
  ROOMDETAILS_BEGIN,
  ROOMDETAILS_SUCCESS,
  ROOMDETAILS_FAILURE,
} from "./common.constant";

export const roomDetailsBegin = () => {
  return {
    type: ROOMDETAILS_BEGIN,
  };
};

export const roomDetailsSuccess = (payload) => {
  return {
    type: ROOMDETAILS_SUCCESS,
    payload,
  };
};

export const roomDetailsFailure = (error) => {
  return {
    type: ROOMDETAILS_FAILURE,
    payload: error,
  };
};


export const fetchRoomDetails = (currentCard, successCB) => {
  const request = http("get", `user/rooms/getRoomDetail?roomId=${currentCard}`, {}, true);
  return (dispatch) => {
    dispatch(roomDetailsBegin());
    request
      .then((response) => {
        successCB();
        dispatch(roomDetailsSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(roomDetailsFailure(error));
        // dispatch(showMessage(error?.message, "error"));
      });
  };
};

export const showMessage = (message, type) => {
  const payload = { message, type };
  return {
    type: SHOW_MESSAGE,
    payload,
  };
};

