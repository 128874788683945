/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  organation: ["admin", "organation"],
  user: ["admin", "organation", "user"],
  onlyGuest: ["guest"],
  anyOne: ["admin", "organation", "user", "guest"]
};

export default authRoles;
