import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import designService from "../../services/design.service";
import rightArrowWhite from "assets/images/icons/right-arrow-white.svg";
// import dateTimeWhite from "assets/images/icons/date-time-white.svg";
import publishedWhite from "assets/images/icons/published-white.svg";
// import chatWhite from "assets/images/icons/chat-white.svg";
// import reloadWhite from "assets/images/icons/reload-white.svg";
import statusWhite from "assets/images/icons/status-white.svg";
import lifecareWhite from "assets/images/icons/lifecare-white.svg";
import activeUserWhite from "assets/images/icons/activeuser-white.svg";
import ImageSlider from ".../../components/ImageSlider";
import RoomDetailsList from "components/RoomDetailsList/RoomDetailsList";
import TabContentTop from "components/TabContentTop.js/TabContentTop";
import ModifyRoomModal from "./components/ModifyRoomModal";
import { deleteRoom, updateRoom } from "./store/room.action";
import DeleteRoomModal from "./components/DeleteRoomModal";
import { fetchRoomDetails, showMessage } from "store/common.action";
import VisibilityBlock from "pages/MyRoom/VisibilityBlock";
import LaunchModal from "./components/LaunchModal";
import { getDateInFormat, http } from "utils";


function RoomDetailsTab({ roomDetailsData }) {
  const [imageIndex, setImageIndex] = useState(
    roomDetailsData?.thumbnailNumber
  );
  const [modal, setModal] = useState({
    modify: false,
    delete: false,
    launch: false,
    achievement: false,
  });
  const toggleModal = (type) => {
    setModal({ ...modal, [type]: !modal[type] });
  };
  const [visibilityModal, setVisibilityModal] = useState(false);
  const toggleVisibilityModal = () => setVisibilityModal(!visibilityModal);
  // const [achievementList, setAchievementList] = useState({});

  // const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  designService.setTabContentHeight();
  window.addEventListener("resize", () => {
    designService.setTabContentHeight();
  });

  const thumbnailSelection = (index) => {
    setImageIndex(index);
  };

  const handleUpdateRoom = (roomDetail) => {
    const successCB = () => {
      toggleModal("modify");
      dispatch(fetchRoomDetails(roomDetailsData._id, () => {}));
    };
    const payload = {
      ...roomDetail,
      roomId: roomDetailsData._id,
      thumbnailNumber: imageIndex,
      roomVisibility: roomDetailsData.roomVisibility,
    };
    dispatch(updateRoom(payload, successCB));
  };

  const handleVisibility = (value) => {
    const successCB = () => {
      toggleVisibilityModal();
      dispatch(fetchRoomDetails(roomDetailsData._id, () => {}));
    };
    const payload = {
      roomId: roomDetailsData._id,
      roomName: roomDetailsData?.roomName,
      description: roomDetailsData?.description,
      thumbnailNumber: imageIndex,
      roomVisibility: value,
    };
    dispatch(updateRoom(payload, successCB));
  };

  const handleDeleteRoom = () => {
    const successCB = () => {
      toggleModal("delete");
      localStorage.setItem(
        "redirectData",
        JSON.stringify({
          flag: false,
          redirectObj: {},
        })
      );
    };

    dispatch(deleteRoom(roomDetailsData._id, successCB));
  };

  const handleLaunchApp = () => {
    const payload = {
      roomId: roomDetailsData._id,
      card: commonData,
      roomName: roomDetailsData.roomName
    };
    http("post", "user/settings/notification", payload, true)
      .then((response) => {
        dispatch(showMessage(response?.message, "info"));
      })
      .catch((error) => {
        dispatch(showMessage(error?.message, "error"));
      });
  };

  const handleLaunchWeb = () => {
    window.open(
      // `${roomDetailsData?.assetWebURL}?token=${token}/assetID=${roomDetailsData.assetId}/roomName=${roomDetailsData.roomName}/roomId=${roomDetailsData._id}`,
      `${roomDetailsData?.assetWebURL}`,
      "_blank"
    );
  };

  const selectStatus = (status) => {
    switch (status) {
      case 0:
        return "DND";
      case 1:
        return "In Use";
      case 2:
        return "Idle";
      case 3:
        return "Close";
      default:
        return "In Use";
    }
  };

  const roomAnalyticsList = [
    {
      id: 0,
      iconClass: "published-icon",
      img: publishedWhite,
      title: "Created",
      value:
        roomDetailsData?.createdAt &&
        (getDateInFormat(roomDetailsData?.createdAt) || "-"),
    },
    // {
    //   id: 1,
    //   iconClass: "reload-icon",
    //   img: reloadWhite,
    //   title: "Recent",
    //   value:
    //     roomDetailsData?.createdAt &&
    //     (getDateInFormat(roomDetailsData?.createdAt) || "-"),
    // },
    {
      id: 2,
      iconClass: "status-icon",
      img: statusWhite,
      title: "Status",
      value: selectStatus(roomDetailsData?.roomStatus) || "-",
    },
    {
      id: 3,
      iconClass: "lifecare-icon",
      img: lifecareWhite,
      title: "Lifetime",
      value: roomDetailsData.lifetime || "-",
    },
    {
      id: 4,
      iconClass: "activeuser-icon",
      img: activeUserWhite,
      title: "Active",
      value: roomDetailsData.active || "-",
    },
    // {
    //   id: 5,
    //   iconClass: "date-time-icon",
    //   img: dateTimeWhite,
    //   title: "Total Time",
    //   value: roomDetailsData.total_minutes || "-",
    // },
    // {
    //   id: 6,
    //   iconClass: "chat-icon",
    //   img: chatWhite,
    //   title: "Total Sessions",
    //   value: roomDetailsData.session_count || "-",
    // },
  ];

  const roomAnalyticsListFun = (assetType) => {
    return assetType === 1
      ? roomAnalyticsList
      : roomAnalyticsList.filter((each) => each.id !== 3 && each.id !== 4);
  };

  const { commonData } = useSelector(({ midbar }) => midbar);
  return (
    <Scrollbars
      className="tab-pane-inner custom-scrollbar"
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderView={(props) => <div {...props} className="content-outer" />}
    >
      <div className="pb-40">
        <TabContentTop roomData={roomDetailsData} />
        {roomDetailsData?.bannerImages && (
          <ImageSlider
            imageIndex={imageIndex}
            thumbnailSelection={thumbnailSelection}
            data={roomDetailsData}
            module="myroom"
            commonData={commonData}
          />
        )}
        <p className="font-14 fw-thin custom-description">
          {roomDetailsData.description}
        </p>
        <RoomDetailsList
          list={roomAnalyticsListFun(roomDetailsData.assetType)}
        />
        <div className="d-flex flex-wrap mt-40">
          {/* {(roomDetailsData?.platform?.includes(1) ||
            roomDetailsData?.platform?.includes(2)) && (
            <button
              className="blue-background-button font-16 fw-normal d-flex align-items-center me-3 mb-2"
              onClick={handleLaunchApp}
            >
              Launch App
            </button>
          )} */}
          {(roomDetailsData?.platform?.includes(1) ||
            roomDetailsData?.platform?.includes(2)) &&
          roomDetailsData?.platform?.includes(5) ? (
            <>
              <button
                className="blue-background-button font-16 fw-semibold d-flex align-items-center me-3 mb-2"
                onClick={() => toggleModal("launch")}
              >
                Launch
              </button>
              <LaunchModal
                isOpen={modal.launch}
                toggle={() => toggleModal("launch")}
                roomDetailsData={roomDetailsData}
                handleLaunchApp={handleLaunchApp}
                handleLaunchWeb={handleLaunchWeb}
              />
            </>
          ) : roomDetailsData?.platform?.includes(1) ||
            roomDetailsData?.platform?.includes(2) ? (
            <button
              className="blue-background-button font-16 fw-semibold d-flex align-items-center me-3 mb-2"
              onClick={handleLaunchApp}
            >
              Launch App
            </button>
          ) : roomDetailsData?.platform?.includes(5) ? (
            <button
              className="blue-background-button font-16 fw-semibold d-flex align-items-center me-3 mb-2"
              onClick={handleLaunchWeb}
            >
              Launch Web
            </button>
          ) : null}
          {commonData === "Host" && roomDetailsData.assetType === 1 && (
            <>
              <button
                className="green-background-button font-16 fw-semibold d-flex align-items-center me-3 mb-2"
                onClick={() => toggleModal("modify")}
              >
                Modify Room
                <i className="right-arrow-icon right-icon arrow-icon d-inline-block">
                  <img src={rightArrowWhite} alt="" />
                </i>
              </button>
              <ModifyRoomModal
                isOpen={modal.modify}
                toggle={() => toggleModal("modify")}
                handleUpdateRoom={handleUpdateRoom}
                roomName={roomDetailsData?.roomName}
                description={roomDetailsData?.description}
              />
            </>
          )}
          {!(commonData === "Host" && roomDetailsData.roomVisibility === 0) && (
            <>
              <button
                className="green-border-button font-16 fw-normal d-flex align-items-center me-3 mb-2"
                onClick={() => toggleModal("delete")}
              >
                Delete Room
              </button>
              <DeleteRoomModal
                isOpen={modal.delete}
                toggle={() => toggleModal("delete")}
                handleDeleteRoom={handleDeleteRoom}
              />
            </>
          )}
          {/* <button
            className="blue-background-button font-16 fw-normal d-flex align-items-center me-3 mb-0"
            onClick={handleProgress}
          >
            Progress Tracker
          </button> */}
          {/* <ProgressTrackerModal
            isOpen={modal.achievement}
            toggle={() => toggleModal("achievement")}
            achievementList={achievementList}
            name={roomDetailsData.assetName}
          /> */}
          {commonData === "Host" && roomDetailsData.assetType === 1 && (
            <VisibilityBlock
              isOpen={visibilityModal}
              toggleModal={toggleVisibilityModal}
              handleVisibility={handleVisibility}
              roomVisibility={roomDetailsData.roomVisibility}
            />
          )}
        </div>
      </div>
    </Scrollbars>
  );
}

export default RoomDetailsTab;
