import {
  DASHBOARD_BEGIN,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  DASHBOARD_TRACKER_BEGIN,
  DASHBOARD_TRACKER_SUCCESS,
  DASHBOARD_TRACKER_FAILURE,
} from "./dashboard.constant";

const initialState = {
  dashboardLoading: false,
  dashboardList: {},
  dashboardError: "",
  progressLoading: false,
  progressList: {},
  progressError: "",
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_BEGIN:
      return {
        ...state,
        dashboardLoading: true,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardList: action.payload,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: action.payload,
      };

    case DASHBOARD_TRACKER_BEGIN:
      return {
        ...state,
        progressLoading: true,
      };
    case DASHBOARD_TRACKER_SUCCESS:
      return {
        ...state,
        progressLoading: false,
        progressList: action.payload,
      };
    case DASHBOARD_TRACKER_FAILURE:
      return {
        ...state,
        progressLoading: false,
        progressError: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
