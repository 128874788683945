import { Modal, ModalBody, ModalFooter } from "reactstrap";
import errorIcon from "assets/images/icons/error-icon.svg";

function DeleteRoomModal(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      centered
      className="custom-modal validation-modal"
    >
      <ModalBody>
        <i className="validation-icon d-block mx-auto mb-2 mb-sm-3">
          <img src={errorIcon} alt="error-icon" />
        </i>
        <p className="font-18 fw-normal mb-2 text-center">Are you sure you want to delete this room?</p>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold gray-background-button"
          title="No"
          onClick={props.toggle}
        >
          No
        </button>
        <button
          type="button"
          className="modal-btn font-16 fw-semibold green-background-button"
          title="Yes"
          onClick={props.handleDeleteRoom}
        >
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteRoomModal;
