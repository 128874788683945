import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from "./login.constant";

const initialState = {
    loading: false,
    loginResponseData: {},
}
const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loginResponseData: action.payload
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default: 
            return state;
    }
}

export default loginReducer;