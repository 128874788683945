import React from "react";
import EachTagsItem from "./EachTagsItem";

function TagsList({tagList}) {
  return (
    <ul className="category-listing">
      {tagList.map((eachTagsItem, index) => (
        <EachTagsItem key={eachTagsItem+index} value={eachTagsItem} />
      ))}
    </ul>
  );
}

export default TagsList;
