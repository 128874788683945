import React from "react";
import EachDetailsItem from "./EachDetailsItem";

function RoomDetailsList(props) {
 
  return (
    <ul className="common-detail-list d-flex flex-wrap align-items-start">
      {props.list.map((listDataItem) => (
        <EachDetailsItem
          key={listDataItem.id}
          id={listDataItem.id}
          iconClass={listDataItem.iconClass}
          title={listDataItem.title}
          img={listDataItem.img}
          value={listDataItem.value}
        />
      ))}
    </ul>
  );
}

export default RoomDetailsList;
