import React, { useEffect, useState } from "react";
import Midbar from "./Midbar";
import Main from "./Main";
import Sidebar from "./Sidebar/Sidebar";
import "assets/css/page-content-style.css";
import classnames from "classnames";
import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "store/common.action";
import "assets/css/dashboard-page.css";

function Layout({ isChild, config, children }) {
  const {
    sidebar,
    midbar,
    prelogin,
    settingspage,
    profilepage,
    dashboardpage,
  } = config || {};
  const { showMessage: show_message } =
    useSelector(({ common }) => common) || {};
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "info",
  });

  const onShowAlert = (message, type) => {
    setAlertMessage({ ...alertMessage, message, type });
    setTimeout(() => {
      setAlertMessage("");
      dispatch(showMessage("", "info"));
    }, 3000);
  };

  useEffect(() => {
   
    if (show_message.message) {
      onShowAlert(show_message.message, show_message.type);
    }
    // return () => {
    //   dispatch(showMessage("","info"))
    // }

    // eslint-disable-next-line
  }, [show_message]);

  if (isChild) return children;

  return (
    <main className="main">
      <Alert
        color={`${alertMessage.type === "error" ? "danger" : "success"}`}
        isOpen={Boolean(alertMessage.message)}
        className="alert-popup"
      >
        <i className="alert-icon success"></i>
        {alertMessage.message}
      </Alert>

      {!prelogin && (
        <div
          className={classnames({
            "main-layout-wrapper": true,
            "settings-page": settingspage,
            "profile-page": profilepage,
            "dashboard-page": dashboardpage,
          })}
        >
          <Sidebar display={sidebar?.display} />
          <div
            className={classnames({
              "page-content-block": true,
              "d-flex": true,
              "w-100": true,
              "align-items-start": settingspage || profilepage,
            })}
          >
            <Midbar display={midbar?.display} />
            <Main>{children}</Main>
          </div>
        </div>
      )}
      {/* Pre login screens will render from here.. */}
      {prelogin && <Main>{children}</Main>}
    </main>
  );
}

export default React.memo(Layout);
