import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router";
import notificationWhite from "assets/images/notification.png";
import deleteWhite from "assets/images/icons/remove-white.svg";
import updateWhite from "assets/images/icons/update-white.svg";
import publishedWhite from "assets/images/icons/published-white.svg";
import { http, getDateInFormat } from "utils";
import NoNotification from "assets/images/notification.svg";

function NotificationBell() {
  const history = useHistory();
  const notificationIcons = [publishedWhite, updateWhite, deleteWhite];
  const [notificationList, setNotificationList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const enterpriseId = localStorage.getItem("enterpriseId");
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    http(
      "get",
      `user/settings/notification/list?enterpriseId=${enterpriseId}`,
      {},
      true
    ).then((response) => {
      setNotificationList(response.data);
    });
  }, [enterpriseId]);

  const clearNotifications = () => {
    http(
      "get",
      `user/settings/notification/clear?enterpriseId=${enterpriseId}`,
      {},
      true
    ).then(() => setNotificationList([]));
  };

  return (
    <div className="notification-wrapper">
      <i className="notification-icon notification-menu d-none">
        <img src={notificationWhite} alt="notification-white" />
      </i>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret className="notification-btn">
          <i className="notification-icon notification-menu">
            <img src={notificationWhite} alt="notification-white" />
          </i>
        </DropdownToggle>
        <DropdownMenu right className="notification-dropdown">
          <div>
            <p className="font-18 notification-title m-0">Notification</p>
            <button
              className="clear-all-btn font-16"
              onClick={clearNotifications}
            >
              Clear
            </button>
          </div>
          {notificationList.length === 0 ? (
            <div className="no-notification">
              <div>
                <p className="font-18 fw-medium">No notification yet</p>
                <p className="m-0 font-13">
                  Stay tuned! Notification about your activity will show up
                  here.
                </p>
              </div>
              <img src={NoNotification} alt="no-notification" />
            </div>
          ) : (
              <Scrollbars
                className="custom-scrollbar"
                style={{ height: "450px" }}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-vertical" />
                )}
                renderThumbHorizontal={(props) => (
                  <div {...props} className="thumb-horizontal" />
                )}
              >
                <div className="notification-content custom-table">
                  {notificationList.map(
                    ({ _id, message, imageTag, createdAt }) => (
                      <DropdownItem key={_id}>
                        <div className="icon-wrap blue">
                          {/* <span className="font-16 fw-semibold">AB</span> */}
                          <i className="icon">
                            <img
                              src={notificationIcons[imageTag]}
                              alt="notification"
                            />
                          </i>
                        </div>
                        <div className="text-wrap">
                          <p className="font-14 notification-text">{message}</p>
                          <span className="d-block font-13 text-right">
                            {getDateInFormat(createdAt)}
                          </span>
                        </div>
                      </DropdownItem>
                    )
                  )}
                </div>
              </Scrollbars>
          )}
          <div className=" notification-setting">
            <button className="font-16" onClick={() => history.push("/settings")}>Notification Settings</button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NotificationBell;
