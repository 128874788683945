// import { useEffect } from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import noUserActivity from "assets/images/icons/girl-swing.svg";
// import { userActivity } from "pages/MyRoom/store/room.action";
import { getDateInFormat } from "utils";

function UserAcitvityTable() {
  // const currentCard = useSelector(
  //   (state) => state.midbar.currentCard
  // );
  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userActivityList = useSelector((state) => state.room.userActivities);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const successCB = () => {};
  //   const payload = {
  //     roomID: currentCard,
  //     enterpriseID: userDetails.enterpriseID,
  //   };
  //   currentCard && dispatch(userActivity(payload, successCB));
  //   // eslint-disable-next-line
  // }, [dispatch]);

  return (
    <>
      {/* {userActivityList?.length === 0 ? ( */}
        {true ? (
        <div className="no-data-block no-activity">
          <div className="no-data-content-block">
            <p className="font-18 fw-medium text-center">
              Oops! We Haven’t Found Any Activity
            </p>
            <p className="font-14 fw-light text-center">
              You haven’t do any acticity, whenever you do something In room. We
              will show here.
            </p>
          </div>
          <div className="img-wrapper">
            <img src={noUserActivity} alt="" />
          </div>
        </div>
      ) :(
        <Table responsive className="custom-table">
          <thead>
            <tr>
              <th className="font-14 fw-normal">User</th>
              <th className="font-14 fw-normal text-center">
                Last Update
              </th>
              <th className="font-14 fw-normal text-center">
                Count
              </th>
              <th className="font-14 fw-normal text-center">
                Event
              </th>
            </tr>
          </thead>
          <tbody>
            {userActivityList?.map((eachActivity, index) => {
              return (
                <tr key={index} >
                  <td className="font-14 fw-normal">
                    {eachActivity.owner_player_name}
                    <span className="d-block font-14 fw-medium">
                      {eachActivity.owner_email}
                    </span>
                  </td>
                  <td className="font-14 fw-normal text-center">
                  {getDateInFormat(eachActivity.updated_at) || "-"}
                  </td>
                  <td className="font-14 fw-normal text-center">
                    {eachActivity.event_count}
                  </td>
                  <td className="font-14 fw-normal text-center">
                    {eachActivity.event_name}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default UserAcitvityTable;