import React from "react";

/* Import images */
import logoWhiteLarge from "assets/images/logo-white-large.png";
import emailSentLogo from "assets/images/icons/email-sent-logo.svg";

/* Import page specific css */
import "assets/css/pre-login-screens.css";

function EmailSent() {
  return (
    <div className="pre-login-screen forgot-password">
      <div className="pre-login-inner">
        <div className="logo-white-large">
          <img alt="" src={logoWhiteLarge} />
        </div>
        <div className="pre-login-page-content-block width-small">
        <i className="change-password-logo d-block mx-auto mb-4">
            <img src={emailSentLogo} alt="change-password-logo" />
          </i>
          <p className="font-22 fw-medium mb-3 text-center">Check Your Email</p>
          <p className="font-16 fw-light mb-3 text-center">
            Please check the email address associated with the username for
            instructions to reset your password.
          </p>
        </div>
      </div>
    </div>
  );
}
export default EmailSent;
