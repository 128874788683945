import ForgotPassword from "./ForgotPassword";

const ForgotPasswordConfig = {
  component: ForgotPassword,
  path: "/forgot-password",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      prelogin: true
    },
  },
  auth: false,
};

export default ForgotPasswordConfig;


