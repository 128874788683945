import axios from "axios";

export default function setupInterceptors(store, history) {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        (error.response?.status === 401 || error.response?.status === 422) 
      ) {
        localStorage.clear();
        window.location.replace("/login");
      }
      return Promise.reject(error);
    }
  );
}

export const http = (
  method = "get",
  request = "",
  payload = {},
  isToken,
  headerOptions = {},
  cancelToken
) => {
  const url = `${process.env.REACT_APP_API_URL}/${request}`;
  let token = localStorage.getItem("userToken");
  token = token ? token.replace("JWT", "").trim() : null;
  const authorization = isToken ? { Authorization: `Bearer ${token}` } : {};
  const options = {
    method,
    url,
    data: payload,
    headers: {
      ...authorization,
      ...headerOptions,
    },
    cancelToken: cancelToken,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
    
        if (error.response && error.response.data) {
          if (error.response.data?.message) {
            let data = error.response.data;
            error.response.data.message = error.response.data.message
              ? error.response.data.message
              : "Something went wrong, Please Try Again";
            if (data.code === 401 || data === "Unauthorized") {
              localStorage.removeItem("userToken");
              window.location.href = "/login";
              if (axios.isCancel(error)) return;
            }
          } else {
            
            error.response.data = {
              message: "Something went wrong, Please Try Again",
            };
          }
          reject(error.response.data);
        } else {
          reject("Network Error");
          // console.error(error);
        }
      });
  });
};
