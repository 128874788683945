import NotificationBell from "./NotificationBell";

function Header() {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="font-28 fw-normal event-title mb-0">My Dashboard</h3>
      <NotificationBell />
    </div>
  );
}

export default Header;
