import {
  ORGANIZATION_BEGIN,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_SWITCH,
} from "./organization.constant";

const initialState = {
  loading: false,
  organizationList: [],
  error: {},
  enterpriseId: "",
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationList: action.payload,
      };
    case ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ORGANIZATION_SWITCH:
      return {
        ...state,
        enterpriseId: action.payload,
      };
    default:
      return state;
  }
};

export default organizationReducer;
