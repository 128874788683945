import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function CardSkeleton() {
  return (
    <div className="events-list custom-scrollbar">
    <ul>
      {[1, 2, 3, 4].map((each) => (
        <li className="d-flex card-skeleton" key={each}>
          <div className="img-wrap">
            <SkeletonTheme
              color="rgba(54, 60, 89, 0.6)"
              highlightColor="#181f3a"
            >
              <Skeleton count={1} />
            </SkeletonTheme>
          </div>
          <div className="text-wrap">
            <h2 className="font-18 block-title">
              <SkeletonTheme
                color="rgba(54, 60, 89, 0.6)"
                highlightColor="#181f3a"
              >
                <Skeleton count={1} />
              </SkeletonTheme>
            </h2>
            <div className="font-10 fw-light organizer">
              <SkeletonTheme
                color="rgba(54, 60, 89, 0.6)"
                highlightColor="#181f3a"
              >
                <Skeleton count={1} />
              </SkeletonTheme>
            </div>
            <div className="font-10 fw-thin mb-2">
              <SkeletonTheme
                color="rgba(54, 60, 89, 0.6)"
                highlightColor="#181f3a"
              >
                <Skeleton count={1} width={75} height={28} />
              </SkeletonTheme>
            </div>
            <div className="font-13 fw-light mb-0">
              <SkeletonTheme
                color="rgba(54, 60, 89, 0.6)"
                highlightColor="#181f3a"
              >
                <Skeleton count={1} />
              </SkeletonTheme>
            </div>
          </div>
        </li>
      ))}
    </ul>
    </div>
  );
}

export default CardSkeleton;
