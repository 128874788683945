import { useEffect } from "react";
import designService from "../../services/design.service";
import UserAcitvityTable from "pages/MyRoom/UserActivityTable";

function UserAcitvityTab() {
  useEffect(() => {
    designService.setTabContentHeight();
    window.addEventListener("resize", () => {
      designService.setTabContentHeight();
    });
  }, []);
  return (
    <div className="tab-pane-inner custom-scrollbar">
      <UserAcitvityTable />
    </div>
  );
}

export default UserAcitvityTab;
