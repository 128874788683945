import {
  SHOW_MESSAGE,
  ROOMDETAILS_BEGIN,
  ROOMDETAILS_SUCCESS,
  ROOMDETAILS_FAILURE,
} from "./common.constant";

const initialState = {
  showMessage: { message: "", type: "info" },
  roomDetailsLoading: false,
  roomDetailsData: {},
  roomDetailsError: {},
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        showMessage: action.payload,
      };
    case ROOMDETAILS_BEGIN:
      return {
        ...state,
        roomDetailsLoading: true,
      };
    case ROOMDETAILS_SUCCESS:
      return {
        ...state,
        roomDetailsLoading: false,
        roomDetailsData: action.payload,
      };
    case ROOMDETAILS_FAILURE:
      return {
        ...state,
        roomDetailsLoading: false,
        roomDetailsError: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
