import React from "react";

function EachTagsItem({ value }) {
  return (
    <li className="badge category-label font-11 fw-thin white opacity-80">
      {value}
    </li>
  );
}

export default React.memo(EachTagsItem);
