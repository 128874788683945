import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
  ],
};
function ImageSlider({
  data,
  module,
  imageIndex,
  thumbnailSelection,
  commonData,
}) {
  return (
    <Slider
      className={`image-slider ${data.bannerImages.length <=
          settings.responsive[0].settings.slidesToShow &&
          window.innerWidth < 480
          ? "no-slider"
          : ""
        } ${data.bannerImages.length <=
          settings.responsive[1].settings.slidesToShow &&
          window.innerWidth >= 480 &&
          window.innerWidth < 1280
          ? "no-slider"
          : ""
        }${data.bannerImages.length <= settings.slidesToShow &&
          window.innerWidth >= 1280
          ? "no-slider"
          : ""
        }`}
      {...settings}
    >
      {data.bannerImages.map((eachImgURL, index) => (
        <div
          key={index}
          className={`img-wrap  
          ${data?.assetType === 0 || module === "social"
              ? "img-default"
              : module === "myroom" && commonData !== "Host"
                ? "img-default"
                : imageIndex === index && "selected"
            }`}
          onClick={module !== "social" ? () => thumbnailSelection(index) : null}
        >
          <img src={eachImgURL} alt="automobile-exhibition-1" />
        </div>
      ))}
    </Slider>
  );
}
export default ImageSlider;