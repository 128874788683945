import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


function ContentSkeleton() {
    return (
        <div className="content-skeleton content-wrapper-block event-details-block flex-grow-1">
            <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                <Skeleton count={1} width={100} className="mb-1" />
            </SkeletonTheme>
            <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                <Skeleton count={1} width={200} className="mb-4" />
            </SkeletonTheme>
            <div className="slider-skeleton mb-4">
                <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                    <Skeleton count={5} className="img-wrap" />
                </SkeletonTheme>
            </div>
            <ul className="common-list-skeleton">
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
            </ul>
            <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                <Skeleton count={1} width={200}  className="mt-2" />
            </SkeletonTheme>
            <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                <Skeleton count={1}  className="mt-2" />
            </SkeletonTheme>
            <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                <Skeleton count={1} width={200}  className="mt-2" />
            </SkeletonTheme>
            <ul className="common-list-skeleton mt-2">
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
                <li className="d-inline-block me-2">
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton circle={true} count={1} width={30} height={30} className="d-block mx-auto" />
                    </SkeletonTheme>
                    <SkeletonTheme color="rgba(54, 60, 89, 0.6)" highlightColor="#181f3a">
                        <Skeleton count={1} width={70} className="mt-2 mb-4" />
                    </SkeletonTheme>
                </li>
            </ul>
        </div>
    );
}

export default ContentSkeleton;
