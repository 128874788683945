import PasswordChanged from "./PasswordChanged";

const PasswordChangedConfig = {
  component: PasswordChanged,
  path: "/password-changed",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      prelogin: true
    },
  },
  auth: false,
};

export default PasswordChangedConfig;


