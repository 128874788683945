import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { roomListFetch } from "pages/Explore/store/explore.action";

export default function usePagination(tab, page, per_page) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentCard = useSelector((state) => state.midbar.currentCard);
  const dispatch = useDispatch();

  useEffect(() => {
    setData([]);
  }, [tab]);

  useEffect(() => {
    setLoading(true);
    let cancel;
    const successCB = (result) => {
      setData((prevData) => {
        return [...new Set([...prevData, ...result?.assetList])];
      });
      setHasMore(result.totalPages > page);
      setLoading(false);
    };
    const cancelToken = new axios.CancelToken((c) => (cancel = c));
    dispatch(
      roomListFetch(tab, currentCard, page, per_page, successCB, cancelToken)
    );
    return () => cancel();
    // eslint-disable-next-line
  }, [page, per_page, dispatch, tab]);

  return { data, hasMore, loading };
}
