import EmailSent from "./EmailSent";

const EmailSentConfig = {
  component: EmailSent,
  path: "/email-sent",
  exact: true,
  layout: {
    config: {
      midbar: {
        display: false,
      },
      sidebar: {
        display: false,
      },
      prelogin: true
    },
  },
  auth: false,
};

export default EmailSentConfig;


