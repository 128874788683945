import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import "assets/css/sidebar-menu-block.css";
import logo from "assets/images/icons/logo-white.svg";
import dashbaordWhite from "assets/images/icons/dashboard-white.svg";
import dashbaordOrange from "assets/images/icons/dashboard-orange.svg";
import exploreWhite from "assets/images/icons/explore-white.svg";
import exploreOrange from "assets/images/icons/explore-orange.svg";
import roomWhite from "assets/images/icons/room-white.svg";
import roomOrange from "assets/images/icons/room-orange.svg";
import socialWhite from "assets/images/icons/social-white.svg";
import socialOrange from "assets/images/icons/social-orange.svg";
// import settingsWhite from "assets/images/icons/settings-white.svg";
// import settingsOrange from "assets/images/icons/settings-orange.svg";
import designService from "services/design.service";
import {
  changeCurrentCard,
  setCommonCard,
  updateMidbarData,
} from "../Midbar/store/midbar.action";
import { getProfileDetails } from "pages/UserProfile/store/userProfile.action";
import UserProfile from "assets/images/user-profile.svg";
import Setting from "assets/images/setting.svg";
import Switch from "assets/images/switch-icon.svg";
import SwitchActive from "assets/images/switch-orange.svg";
import Logout from "assets/images/log-out.svg";
import Arrow from "assets/images/icons/right-arrow-white.svg";
import ArrowActive from "assets/images/icons/right-arrow-orange.svg";
import {
  fetchOrganizationList,
  organizationSwitch,
} from "pages/OrganizationSelection/store/organization.action";
import { Scrollbars } from "react-custom-scrollbars";
import { getFormattedText } from "utils";

function Sidebar({ display }) {
  const history = useHistory();
  const location = useLocation();
  const currentPanel = location.pathname;
  const dispatch = useDispatch();
  const enterpriseId = localStorage.getItem("enterpriseId");
  const [orgId, setOrgId] = useState(enterpriseId);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);
  const { organizationList, enterpriseId: enterpriseIdStore } = useSelector(
    ({ organization }) => organization
  );

  useEffect(() => {
    dispatch(getProfileDetails("GET", {}, () => {}));
    const successCB = () => {};
    dispatch(fetchOrganizationList(successCB));
  }, [dispatch, enterpriseIdStore]);

  const { profileDetails } = useSelector(({ profile }) => profile);

  useEffect(() => {
    designService.scrollToActive();
  }, []);

  if (!display) return null;

  const sideNavList = [
    {
      id: 0,
      name: "Dashboard",
      normalImage: dashbaordWhite,
      hoverImage: dashbaordOrange,
      url: "/",
    },
    {
      id: 1,
      name: "Explore",
      normalImage: exploreWhite,
      hoverImage: exploreOrange,
      url: "/explore",
    },
    {
      id: 2,
      name: "My Room",
      normalImage: roomWhite,
      hoverImage: roomOrange,
      url: "/myroom",
    },
    {
      id: 3,
      name: "Social",
      normalImage: socialWhite,
      hoverImage: socialOrange,
      url: "/social",
    },
  ];

  const handleSwitchOrganization = (orgId) => {
    setOrgId(orgId);
    localStorage.setItem("enterpriseId", orgId);
    dispatch(organizationSwitch(orgId));
  };

  const listItems = sideNavList.map((sideNavListItem) => (
    <li className="text-center" key={sideNavListItem.id}>
      <NavLink
        to={sideNavListItem.url}
        exact
        activeClassName="active"
        className="font-13"
        title={sideNavListItem.name}
        onClick={() => {
          if (currentPanel !== sideNavListItem.url) {
            dispatch(updateMidbarData([]));
            dispatch(changeCurrentCard(null));
            dispatch(setCommonCard(""));
            localStorage.setItem(
              "redirectData",
              JSON.stringify({
                flag: false,
                redirectObj: {},
              })
            );
          }
        }}
      >
        <i
          className={
            sideNavListItem.name.replace(" ", "-").toLowerCase() + "-icon"
          }
        >
          <img
            src={sideNavListItem.normalImage}
            alt={
              sideNavListItem.name.replace(" ", "-").toLowerCase() + "-white"
            }
            className="normal"
          />
          <img
            src={sideNavListItem.hoverImage}
            alt={
              sideNavListItem.name.replace(" ", "-").toLowerCase() + "-orange"
            }
            className="active"
          />
        </i>
        {sideNavListItem.name}
      </NavLink>
    </li>
  ));

  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <aside className="sidebar-menu-block">
      <div className="logo-nav-block">
        <NavLink className="panel-logo" to="/" exact>
          <i className="logo-icon">
            <img src={logo} alt="logo-white" />
          </i>
        </NavLink>
        <ul className="sidebar-menu">{listItems}</ul>
      </div>
      {/* <NavLink
        to="/userProfile"
        exact
        activeClassName="active"
        className="font-13 profile-link text-center"
        title="User Profile"
      >
        {Object.keys(profileDetails).length && (
          <div className="img-wrap">
            {profileDetails.profileImage ? (
              <img src={profileDetails.profileImage} alt="profile" />
            ) : (
              <h3 className="font-16 fw-normal text-uppercase mb-0">
                {profileDetails?.name?.[0]}
              </h3>
            )}
          </div>
        )}
        Profile
      </NavLink> */}
      <Dropdown
        direction="right"
        isOpen={dropdownOpen}
        toggle={toggle}
        className="w-100"
      >
        <DropdownToggle className="profile-link font-13 text-center">
          {Object.keys(profileDetails).length && (
            <div className="img-wrap">
              {profileDetails.profileImage ? (
                <img src={profileDetails.profileImage} alt="profile" />
              ) : (
                <h3 className="font-16 fw-normal text-uppercase mb-0">
                  {profileDetails?.name?.[0]}
                </h3>
              )}
            </div>
          )}
          Profile
        </DropdownToggle>
        <DropdownMenu className="dropdown-list">
          <DropdownItem
            className="dropdown-content"
            onClick={() => history.push("/userProfile")}
          >
            <img src={UserProfile} alt="user-profile" />
            User Profile
          </DropdownItem>
          <DropdownItem
            className="dropdown-content"
            onClick={() => history.push("/settings")}
          >
            <img src={Setting} alt="setting" />
            Settings
          </DropdownItem>
          <Dropdown
            direction="right"
            isOpen={dropdownOpen2}
            toggle={toggle2}
            className="dropdown-content switch-dropdown w-100"
          >
            <DropdownToggle className="sub-dropdown p-0">
              <img src={Switch} alt="switch-organization" className="default" />
              <img
                src={SwitchActive}
                alt="switch-organization"
                className="active"
              />
              Switch Organization
              <img src={Arrow} alt="arrow" className="right-arrow default" />
              <img
                src={ArrowActive}
                alt="arrow"
                className="right-arrow active"
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-list sub-list">
              <Scrollbars
                className="custom-scrollbar"
                style={{ height: "130px" }}
                renderThumbHorizontal={(props) => (
                  <div {...props} className="thumb-horizontal" />
                )}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-vertical" />
                )}
                renderView={(props) => <div {...props} />}
              >
                {organizationList.map(({ _id, name, isLicenceUsed }) => (
                  <DropdownItem
                    onClick={() => handleSwitchOrganization(_id)}
                    disabled={isLicenceUsed === 0}
                    key={_id}
                    className={`dropdown-content ${
                      orgId === _id && "active-item"
                    }`}
                  >
                    {getFormattedText(name, 12) || "-"}{" "}
                    {isLicenceUsed === 0 && "(u)"}
                  </DropdownItem>
                ))}
              </Scrollbars>
            </DropdownMenu>
          </Dropdown>
          <DropdownItem className="dropdown-content" onClick={handleLogout}>
            <img src={Logout} alt="logout" />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </aside>
  );
}

export default Sidebar;
