import { Input } from "reactstrap";
import searchWhite from "assets/images/icons/search-white.svg";
import { debounce } from "../../utils/debounce";
// import { changeRedirectFlag } from "pages/Explore/store/explore.action";

function SearchBar({ search, setSearch, setPage }) {
  const handleSearch = (event) => {
    setPage(1);
    setSearch(event.target.value);
    // dispatch(changeRedirectFlag(false, {}, "myroom"));
    localStorage.setItem(
      "redirectData",
      JSON.stringify({ flag: false, redirectObj: {} })
    );
    if (event.target.value === "") {
      // dispatch(changeRedirectFlag(false, {}, "myroom"));
      localStorage.setItem(
        "redirectData",
        JSON.stringify({ flag: false, redirectObj: {} })
      );
    }
  };

  const debouncedChangeHandler = debounce(handleSearch, 300)

  return (
    <div className="search-input-wrap">
      <Input
        type="text"
        className="search-input font-14"
        placeholder="Search"
        onChange={debouncedChangeHandler}
      />
      <i className="search-icon">
        <img src={searchWhite} alt="search-white" />
      </i>
    </div>
  );
}

export default SearchBar;
