import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchMidbar } from "layout/Midbar/store/midbar.action";

export default function useSearch(
  currentPanel,
  roomTypeFilter,
  search,
  sort,
  duration,
  type,
  page,
  per_page
) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();
  const { enterpriseId } = useSelector(({ organization }) => organization);

  useEffect(() => {
    setData([]);
  }, [search, roomTypeFilter, sort, duration, type, enterpriseId]);

  useEffect(() => {
    if (
      currentPanel === "explore" ||
      currentPanel === "myroom" ||
      currentPanel === "social"
    ) {
      setLoading(true);
      let cancel;

      const successCB = (result, mainData) => {
        setData((prevData) => {
          return [...new Set([...prevData, ...mainData])];
        });
        setHasMore(result.totalPages > page);
        setLoading(false);
      };
      const payload = {
        search,
        sort,
        duration,
        type,
        page,
        per_page,
      };
      const cancelToken = new axios.CancelToken((c) => (cancel = c));
      dispatch(
        fetchMidbar(
          payload,
          currentPanel,
          roomTypeFilter,
          successCB,
          cancelToken,
          page
        )
      );
      return () => cancel();
    }
  }, [
    search,
    page,
    roomTypeFilter,
    sort,
    duration,
    type,
    currentPanel,
    per_page,
    dispatch,
    enterpriseId,
  ]);

  return { data, hasMore, loading };
}
