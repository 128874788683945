export const UPDATEROOM_BEGIN = "UPDATEROOM_BEGIN";
export const UPDATEROOM_SUCCESS = "UPDATEROOM_SUCCESS";
export const UPDATEROOM_FAILURE = "UPDATEROOM_FAILURE";

export const INVITEDUSERS_BEGIN = "INVITEDUSERS_BEGIN";
export const INVITEDUSERS_SUCCESS = "INVITEDUSERS_SUCCESS";
export const INVITEDUSERS_FAILURE = "INVITEDUSERS_FAILURE";

export const ADDINVITEE_BEGIN = "ADDINVITEE_BEGIN";
export const ADDINVITEE_SUCCESS = "ADDINVITEE_SUCCESS";
export const ADDINVITEE_FAILURE = "ADDINVITEE_FAILURE";

export const DELETEINVITEE_BEGIN = "DELETEINVITEE_BEGIN";
export const DELETEINVITEE_SUCCESS = "DELETEINVITEE_SUCCESS";
export const DELETEINVITEE_FAILURE = "DELETEINVITEE_FAILURE";

export const USERACTIVITY_BEGIN = "USERACTIVITY_BEGIN";
export const USERACTIVITY_SUCCESS = "USERACTIVITY_SUCCESS";
export const USERACTIVITY_FAILURE = "USERACTIVITY_FAILURE";

