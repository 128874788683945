import { http } from "utils";
import {
  MIDBAR_BEGIN,
  MIDBAR_SUCCESS,
  MIDBAR_FAILURE,
  UPDATE_MIDBAR,
  CURRENTCARD,
  COMMON_CARD,
} from "./midbar.constant";

export const changeCurrentCard = (id) => {
  return {
    type: CURRENTCARD,
    payload: id,
  };
};

export const setCommonCard = (data) => {
  return {
    type: COMMON_CARD,
    payload: data,
  };
};

export const midbarBegin = () => {
  return {
    type: MIDBAR_BEGIN,
  };
};

export const midbarSuccess = (payload) => {
  return {
    type: MIDBAR_SUCCESS,
    payload,
  };
};

export const midbarFailure = (error) => {
  return {
    type: MIDBAR_FAILURE,
    payload: error,
  };
};

export const updateMidbar = (data) => {
  return {
    type: UPDATE_MIDBAR,
    payload: data,
  };
};

export const fetchMidbar = (
  payload,
  currentPanel,
  roomType,
  successCB,
  cancel,
  page
) => {
  const getUrl = () => {
    const obj = {
      page: payload.page,
      per_page: payload.per_page,
      key:
        payload.sort === "ascName"
          ? "name"
          : payload.sort === "descName"
          ? "name"
          : "createdAt",
      sort:
        payload.sort === "descName" || payload.sort === "descDate"
          ? "desc"
          : "asc",
      duration: payload.duration,
      type: payload.type,
      matchParams: payload.search,
    };

    if (obj.type === 2) {
      delete obj.type;
    }
    if (obj.duration === 50) {
      delete obj.duration;
    }
    if (obj.matchParams === "") {
      delete obj.matchParams;
    }
    let str = "";
    for (let key of Object.keys(obj)) {
      str += `${key}=${obj[key]}&`;
    }

    const enterpriseId  = localStorage.getItem("enterpriseId");
    switch (currentPanel) {
      case "explore":
        return `user/assets/getAssetsList?enterpriseId=${enterpriseId}&${str.slice(0,-1)}`;
      case "myroom":
        if (roomType === "All Rooms") {
          return `user/rooms/getRoomList?enterpriseId=${enterpriseId}&${str.slice(0, -1)}`;
        } else if (roomType === "Host") {
          return `user/rooms/getRoomList?enterpriseId=${enterpriseId}&roomType=Host&${str.slice(0, -1)}`;
        } else if (roomType === "Invited") {
          return `user/rooms/getInvitedUserRoomList?enterpriseId=${enterpriseId}&${str.slice(0, -1)}`;
        } else if (roomType === "Public") {
          return `user/rooms/getRoomList?enterpriseId=${enterpriseId}&roomType=public&${str.slice(0, -1)}`;
        }
        return `user/rooms/getRoomList?enterpriseId=${enterpriseId}&roomType=Host&${str.slice(0, -1)}`;
      case "social":
        return `user/rooms/getSocialRoom?enterpriseId=${enterpriseId}&${str.slice(0, -1)}`;
      default:
        return `user/rooms/getRoomList?enterpriseId=${enterpriseId}&roomType=Host&${str.slice(0, -1)}`;
    }
  };
  const request = http("get", getUrl(), {}, true, {}, cancel);
  return (dispatch) => {
    dispatch(midbarBegin());
    request
      .then((response) => {
        const redirectData = JSON.parse(localStorage.getItem("redirectData"));
        let mainData = [];
        if (redirectData?.flag) {
          mainData = response.result.data.filter(
            (each) => each.room_id !== redirectData.redirectObj.room_id
          );
          successCB && successCB(response.result, mainData);
        } else {
          mainData = response.data?.assetList;
          successCB && successCB(response.data, mainData);
        }

        dispatch(midbarSuccess(response.data));
        if (page === 1) {
          if (currentPanel === "explore") {
            dispatch(changeCurrentCard(mainData?.[0]?.assetId));
          } else if (currentPanel === "myroom") {
            //     if (redirectData?.flag) {
            //       dispatch(changeCurrentCard(redirectData?.redirectObj?.room_id));
            //       dispatch(
            //         setCommonData({
            //           slider: redirectData?.redirectObj?.asset_banner_url,
            //           card: redirectData?.redirectObj?.card,
            //           publisher: redirectData?.redirectObj?.publisher,
            //           assetId: redirectData?.redirectObj?.asset_id,
            //           assetPlatforms: redirectData?.redirectObj?.asset_platforms,
            //           roomCategory:  redirectData?.redirectObj?.room_category,
            //         })
            //       );
            //     } else {
            dispatch(changeCurrentCard(mainData?.[0]?._id));
            dispatch(setCommonCard(mainData?.[0]?.userType));
            //     }
            // }
          } else {
            dispatch(changeCurrentCard(mainData?.[0]?._id));
          }
        }
      })
      .catch((error) => {
        console.log("midbar error caught", error);
        dispatch(midbarFailure(error));
      });
  };
};

export const updateMidbarData = (data) => {
  return (dispatch) => {
    const redirectData = JSON.parse(localStorage.getItem("redirectData"));
    if (redirectData?.flag) {
      const addedData = [redirectData.redirectObj, ...data];
      dispatch(updateMidbar(addedData));
    } else {
      dispatch(updateMidbar(data));
    }
  };
};
