import OrganizationSelection from "./OrganizationSelection";

const OrganizationSelectionConfig = {
  component: OrganizationSelection,
  path: "/organization-selection",
  exact: true,
  layout: {
    config: {
      sidebar: {
        display: false,
      },
      midbar: {
        display: false,
      },
      prelogin: true
    },
  },
  auth: false,
};

export default OrganizationSelectionConfig;
